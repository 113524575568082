<template>
    <div class="main">
        <Tag
            :tags="tags"
            :isBind="true"
            @dangerTag="unBind"
            @addTags="moreTagsOpen = true"
        ></Tag>
        <el-dialog title="标签列表" width="30%" v-model="moreTagsOpen">
            <label-dialog @getCheckedTags="getCheckedTags" :category="category" :categoryId="categoryId"></label-dialog>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="moreTagsOpen = false"
                        >取 消</el-button>
                    <el-button
                        type="primary"
                        @click="bindTags"
                        >确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import LabelDialog from "./LabelDialog.vue";
import Tag from "./Tag.vue";
import { fetchData } from "../../../api/api";

export default {
    name: "AdminLabel",
    components: {
        Tag,
        LabelDialog,
    },
    computed: {},
    props: ["category", "categoryId"],
    emits: [],
    data() {
        return {
            // category: 'bureau',
            // categoryId: 1,
            moreTagsOpen: false,
            checkedTags: [],
            tags: [],
        };
    },
    created() {
        this.getTags();
    },
    watch: {
        categoryId() {
            this.getTags();
        }
    },
    methods: {
        getTags() {
            fetchData(
                { other_id: this.categoryId, category: this.category, require_unbound_tags:0 },
                "/bureau/v1.2/listOtherTags",
                "GET"
            ).then((res) => {
                this.tags = res.data;
            });
        },
        unBind(id) {
            fetchData({other_id: this.categoryId, tag_id: id}, "/bureau/v1.2/unbindTag", "POST").then(() => {
                this.getTags();
                this.$message.success("解绑成功");
            });
        },
        getCheckedTags(tags) {
            this.checkedTags = tags;
        },
        bindTags() {
            if (this.checkedTags.length === 0) {
                this.$message.error("请选择要绑定的标签");
                return false;
            }
            this.checkedTags.forEach((tag, index) => {
                fetchData({other_id: this.categoryId, tag_id: tag}, "/bureau/v1.2/bindTag", "POST").then(() => {
                    if (this.checkedTags.length === (index+1)) {
                        this.getTags();
                        this.moreTagsOpen = false;
                        this.$message.success("绑定成功");
                    }
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.main {
    text-align: left;
}
</style>
