<template>
    <div>
        <el-form ref="form" :model="itemData" label-width="160px">
            <el-form-item label="课程名称">
                <el-input v-model="itemData.name"></el-input>
            </el-form-item>
            <el-form-item label="课程封面">
                <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
                           :show-file-list="false" :on-success="addAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <el-image v-if="itemData.image_url" :src="itemData.image_url"></el-image>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="itemData.type" placeholder="请选择类型" @change="$forceUpdate()">
                    <el-option v-for="item in typeOption" :key="item.sign" :label="item.title" :value="item.sign">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类别">
                <el-radio v-model="itemData.category" :label="1">普通课程</el-radio>
                <el-radio v-model="itemData.category" :label="2">教师训练用课程</el-radio>
            </el-form-item>
            <el-form-item label="课程介绍">
                <el-input v-model="itemData.intro" type="textarea" placeholder="请输入课程介绍" rows="3"></el-input>
            </el-form-item>
        </el-form>


        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="(item,index) in lessonData" :label="item" :key="index">{{ item.name }}</el-checkbox>
        </el-checkbox-group>

        <div class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="handleAddClone">确 定</el-button>
        </div>
    </div>

</template>
<script>
import { fetchData } from "@/api/api";

export default {
    props: {
        courseItem: { type: Object }
    },
    data() {
        return {
            loading: true,
            addData: {},
            itemData: {},
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
            sign: '',
            typeOption: [
                {
                    sign: "scratch",
                    title: "Scratch"
                },
                {
                    sign: "python",
                    title: "python"
                },
                {
                    sign: "LEGO",
                    title: "乐高"
                },
                {
                    sign: "robot",
                    title: "机器人"
                },{
                    sign: "scratchJr",
                    title: "scratchJr"
                },{
                    sign: "guodong",
                    title: "guodong"
                },
            ],
            course_id: 0,
            checkAll: false,
            checkedCities: [],
            lessonData: [],
            isIndeterminate: true,
            imageId: 0,
            new_course_id: 0,
            new_lesson_id: 0,
            old_lesson_id: 0,
        };
    },
    mounted() {
        this.itemData = this.courseItem;
        this.course_id = this.courseItem.id;
        console.log(this.itemData);
        this.getLessonData();

    },
    watch: {},
    methods: {
        handleAddClone() {
            console.log(this.checkedCities)
            this.itemData.category = 3;//克隆数据
            fetchData(this.itemData, "/admin/v1/createCourse", "POST").then(
                (res) => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });

                    this.new_course_id = res.data;
                    console.log(res.data)
                    this.checkedCities.forEach((item) => {
                        let params = {
                            course_id: this.new_course_id,
                            image_id: item.image_id,
                            lesson_plan: item.lesson_plan,
                            lesson_text: item.lesson_text,
                            name: item.name
                        }
                        fetchData(params, "/edu/v1/createLesson", "POST").then((res_less) => {
                            this.new_lesson_id = res_less.data;
                            let new_lesson_id = res_less.data;

                            if (item.video_id !== 0) {
                                fetchData(
                                    {
                                        code_id: 0,
                                        lesson_id: new_lesson_id,
                                        video_id: item.video_id
                                    },
                                    "/admin/v1/videoBindingToLesson",
                                    "POST"
                                ).then(() => {
                                });
                            }

                            if (item.act_id !== 0) {
                                let params = [{
                                    lesson_id: new_lesson_id,
                                    act_id: item.act_id
                                }, "/edu/v1.2/bindLessonAct", "GET"];
                                fetchData(...params).then(() => {
                                });
                            }

                            fetchData(
                                { lesson_id: item.id },
                                "/edu/v1.2/listExtraLessonCode",
                                "GET"
                            ).then((res_code) => {
                                if (res_code.data.length !== 0) {
                                    fetchData(
                                        {
                                            lesson_id: new_lesson_id,
                                            code_id: res_code.data[0].code_id,
                                            code_title: res_code.data[0].code_title,
                                            code_context: res_code.data[0].code_context,
                                        },
                                        "/edu/v1.2/bindExtraLessonCode",
                                        "GET"
                                    ).then(() => {
                                    });
                                }
                            });

                            let params1 = [
                                { edu_lesson_id: item.id },
                                "/edu/v1.2/listEduLessonRawCode",
                                "POST",
                            ];
                            fetchData(...params1).then((res_raw_code) => {
                                if (res_raw_code.data.length !== 0) {
                                    let params = [{
                                        edu_lesson_id: new_lesson_id,
                                        raw_code_id: res_raw_code.data[0].raw_code_id,
                                    }, "/edu/v1.2/bindEduLessonRawCode", "POST"];
                                    fetchData(...params).then(() => {
                                    });
                                }
                            });

                            fetchData({
                                lesson_id: item.id,
                                page: 1,
                                limit: 99999
                            }, "/admin/v1/listLessonStep", "GET").then(
                                (res_step) => {
                                    if (res_step.data.list !== 0) {
                                        let stepList = res_step.data.list;
                                        stepList.forEach((itemStep) => {
                                            fetchData({
                                                serial_num: itemStep.serial_num,
                                                title: itemStep.title,
                                                content: itemStep.content,
                                                lesson_id: new_lesson_id
                                            }, "/admin/v1/addLessonStep", "POST").then(
                                                (res_add_step) => {
                                                    let step_id = res_add_step.data;
                                                    console.log(step_id);
                                                    fetchData({ step_id: itemStep.id }, "/admin/v1/listLessonTemCon", "GET").then(
                                                        (res_con) => {
                                                            if (res_con.data.length !== 0) {
                                                                res_con.data.forEach((item_con,index_con) => {
                                                                    fetchData({
                                                                        step_id: step_id,
                                                                        sort: item_con.sort,
                                                                        template_id: item_con.template_id,
                                                                        visible_type: item_con.visible_type,
                                                                        content: item_con.content
                                                                    }, "/admin/v1/addLessonTemCon", "POST").then(
                                                                        () => {
                                                                            if(index_con+1 === res_con.data.length){
                                                                                loading.close();
                                                                                this.$emit('cloneSuccess')
                                                                            }
                                                                        }
                                                                    );
                                                                });
                                                            }
                                                        }
                                                    );
                                                }
                                            );
                                        });
                                    }
                                }
                            );
                        });
                    })
                }
            );
        },
        handleClose() {
            this.$emit('handleCloseClone')
        },
        getLessonData() {
            fetchData({ course_id: this.course_id, page: 1, limit: 2000 }, "/edu/v1/listLesson", "GET").then((res) => {
                console.log(res.list)
                this.lessonData = res.list;
            });
        },
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.lessonData : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.lessonData.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.lessonData.length;
            console.log(value);
        },
        addAvatarSuccess(res, file) {
            this.imageId = res.data.id;
            this.itemData.image_url = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isLt2M;
        },
    }
};
</script>
<style lang="sass" scoped>
</style>
