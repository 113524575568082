<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 课程动作图
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程图片组" name="first">
          <ActionGroup
            v-if="activeName === 'first'"
            @handleCheck="handleCheckPhoto"
          ></ActionGroup>
        </el-tab-pane>
        <el-tab-pane :label="label" name="second" disabled>
          <ActionPhoto></ActionPhoto>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <el-dialog
      title="上传图片"
      v-model="updateAlertState"
      width="60%"
      :before-close="handleClose"
    >
      <PhotoUpdate></PhotoUpdate>
    </el-dialog> -->
  </div>
</template>

<script>
import ActionGroup from "./components/ActionGroup";
import ActionPhoto from "./components/ActionPhoto.vue";
export default {
  components: { ActionGroup, ActionPhoto },
  data() {
    return { activeName: "first", label: "动作图片", updateAlertState: true };
  },
  methods: {
    handleClick() {
      console.log(this.activeName);
    },
    handleCheckPhoto(row) {
      console.log(row);
      this.activeName = "second";
      this.label = row.name + "-" + "动作图片";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
