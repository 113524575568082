<template>
    <div ref="editor"></div>
    <!-- <button @click='syncHTML'>同步内容</button> -->
    <!-- <div :innerHTML='content.html'></div> -->
</template>

<script>
import { onMounted, onBeforeUnmount, ref, reactive } from "vue";
import WangEditor from "wangeditor";

export default {
    name: "app",
    props: {
        lessonPlan: String,
        getEditorContent: Function,
    },
    setup(props) {
        const editor = ref();
        const content = reactive({
            html: props.lessonPlan,
            text: "",
        });

        let instance;
        onMounted(() => {
            instance = new WangEditor(editor.value);
            Object.assign(instance.config, {
                onchange() {
                    // console.log("change");
                    syncHTML();
                    props.getEditorContent(content.html);
                },
            });

            instance.create();
            instance.txt.html(props.lessonPlan);
        });

        onBeforeUnmount(() => {
            instance.destroy();
            instance = null;
        });

        const syncHTML = () => {
            content.html = instance.txt.html();
        };

        const theAlert = () => {
            alert(223344);
        };

        const setHtml = (val) => {
            instance.txt.html(val);
        }

        return {
            theAlert,
            syncHTML,
            setHtml,
            editor,
            content,
            instance,
        };
    },
    watch: {
        lessonPlan(newValue) {
            this.setHtml(newValue);
        }
    }

    // mounted() {
    //     // this.theAlert(); // 1
    //     console.log(this.content);
    // },
    // created() {
    //     console.log(this.content);
    // },
    // data() {
    //     return {
    //         lessonPlan : this.content.html
    //     };
    // },
    // methods: {

    // }
};
</script>