// import { stringifyQuery } from "vue-router";

const dateUtils = {};

//格式化时间
dateUtils.formatDate = (time, format = 'YY-MM-DD hh:mm:ss') => {
    var date = new Date(time * 1000);
    var year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds();
    var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return '0' + index;
    });

    var newTime = format.replace(/YY/g, year)
        .replace(/MM/g, preArr[month] || month)
        .replace(/DD/g, preArr[day] || day)
        .replace(/hh/g, preArr[hour] || hour)
        .replace(/mm/g, preArr[min] || min)
        .replace(/ss/g, preArr[sec] || sec);

    return newTime;
}
// 获取当前时间前后n天日期
dateUtils.getDateStr = (AddDayCount, format = 'YY-MM-DD hh:mm:ss') => {
    var dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
    return dateUtils.formatDate(dd.getTime() / 1000, format);
}

// 字符串转时间戳
dateUtils.dateStrChangeTimeTamp = dateStr => {
    dateStr = dateStr.substring(0, 19);
    dateStr = dateStr.replace(/-/g, '/');
    return new Date(dateStr).getTime() / 1000;
}

// 小时转分钟
/**
 *
 * @param {any} hour 时间
 * @param {number} type 类型 0 返回 object   1 返回string
 * @param {string} textH 分隔符（小时）
 * @param {string} textM 分隔符（分钟）
 * @returns
 */
dateUtils.hourTomMinute = (hour, type = 0, textH = '小时', textM = '分钟') => {
    let data = (hour + '').split('.'),
        hours = parseInt(data[0]),
        minute = data[1] === undefined ? 0 : Math.round((hour-hours) * 60);
    if (type == 0) {
        return {
            hour: hours,
            minute: minute
        }
    }
    return (hours === 0 ? '' : hours + textH) + (minute === 0 ? '' : minute + textM);
}

export default dateUtils;
