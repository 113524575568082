<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="bureauAdd"
          >添加机构</el-button
        >
        <el-select
          v-model="selectTags"
          multiple
          placeholder="选择标签搜索"
          class="right"
          @change="handleSearch"
        >
          <el-option
            v-for="item in bureauTags"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="query.name"
          placeholder="机构名"
          class="handle-input mr10 right"
          @change="handleSearch"
        >
        </el-input>
      </div>
      <not-found v-if="bureauData.length === 0" text="机构找不到啦"></not-found>

      <el-row :gutter="20">
        <el-col
          :span="6"
          v-for="(item, index) in bureauData"
          :key="index"
          style="max-width: 100%"
        >
          <el-card
            shadow="always"
            style="margin-bottom: 40px; min-width: 360px"
          >
            <div class="bureau-box">
              <el-row
                :gutter="10"
                class="topPickBox"
                :class="unfoldBureau.indexOf(item.id) !== -1 ? 'checked' : ''"
                @click="checkBureau(item.id)"
              >
                <el-col :span="9">
                  <div class="bureau-avatar">
                    <img :src="item.avatar_url" alt="" />
                  </div>
                </el-col>
                <el-col :span="15" class="content">
                  <p class="bureau-name">{{ item.name }}</p>
                  <p :title="item.phone">
                    <i class="el-icon el-icon-phone"></i>{{ item.phone }}
                  </p>
                  <p :title="item.create_time">
                    <i class="el-icon el-icon-date"></i>{{ item.create_time }}
                  </p>
                </el-col>
              </el-row>
              <div class="conPickBox">
                <!-- 标签 -->
                <div class="pickBox">
                  <p class="theTag">
                    <span>标签</span>
                  </p>
                  <div class="content">
                    <admin-label
                      category="bureau"
                      :categoryId="item.id"
                      style="font-size: 12px"
                    ></admin-label>
                  </div>
                </div>
                <!-- 附加信息 -->
                <div class="pickBox">
                  <p class="theTag">
                    <span>附加信息</span>
                  </p>
                  <div class="content">
                    <el-row :gutter="10" style="text-align: center">
                      <el-col :span="8">
                        <reference></reference>
                        <el-popover
                          placement="bottom"
                          width="200"
                          trigger="click"
                          @show="showGroupsInbureau(item.id)"
                        >
                          <el-table
                            :data="groupsInbureau"
                            size="mini"
                            :border="true"
                          >
                            <el-table-column
                              width="150"
                              label="班级名"
                              property="group_name"
                            >
                            </el-table-column>
                            <el-table-column
                              width="150"
                              label="课程名"
                              property="course_name"
                            >
                            </el-table-column>
                            <el-table-column
                              width="150"
                              label="当前课时名"
                              property="last_lesson_name"
                            >
                            </el-table-column>
                            <el-table-column width="150" label="进度">
                              <template #default="scope">
                                <el-progress
                                  :percentage="
                                    groupsLessonPlan(
                                      scope.row.finished_lesson_num,
                                      scope.row.lesson_num
                                    )
                                  "
                                ></el-progress>
                              </template>
                            </el-table-column>
                          </el-table>
                          <template v-slot:reference>
                            <el-button size="mini">班级信息</el-button>
                          </template>
                        </el-popover>
                      </el-col>
                      <el-col :span="8">
                        <reference></reference>
                        <el-popover
                          placement="bottom"
                          width="200"
                          trigger="click"
                          @show="showHelpersInbureau(item.id)"
                        >
                          <el-button
                            icon="el-icon-s-custom"
                            size="mini"
                            round
                            plain
                            @click="bindingHelper(item.id)"
                            style="margin-bottom: 10px"
                            >绑定服务人员
                          </el-button>
                          <el-table
                            :data="helpersInbureau"
                            size="mini"
                            :border="true"
                          >
                            <el-table-column width="150" label="头像">
                              <template #default="scope">
                                <el-avatar
                                  shape="square"
                                  :size="40"
                                  :src="scope.row.avatar"
                                ></el-avatar>
                              </template>
                            </el-table-column>
                            <el-table-column
                              width="100"
                              property="name"
                              label="姓名"
                            ></el-table-column>
                          </el-table>
                          <template v-slot:reference>
                            <el-button size="mini">服务人员</el-button>
                          </template>
                        </el-popover>
                      </el-col>
                      <el-col :span="8">
                        <reference></reference>
                        <el-popover
                          placement="bottom"
                          title="标题"
                          width="200"
                          trigger="click"
                          @show="showSubBureau(item.id)"
                        >
                          <el-button
                            icon="el-icon-s-custom"
                            size="mini"
                            round
                            plain
                            @click="bindingSubordinate(item.id)"
                            style="margin-bottom: 10px"
                            >绑定下级机构
                          </el-button>
                          <el-table :data="subsInBureauList">
                            <el-table-column
                              property="bureau_name"
                              label="姓名"
                            ></el-table-column>
                          </el-table>
                          <template v-slot:reference>
                            <el-button size="mini">下级机构</el-button>
                          </template>
                        </el-popover>
                      </el-col>
                      <el-col :span="8">
                        <el-button
                          size="mini"
                          @click="showGoodsInbureau(item.id)"
                          >套餐信息
                        </el-button>
                      </el-col>
                      <el-col :span="8">
                        <el-button size="mini" @click="setBureauTime(item.id)"
                          >设置时间</el-button
                        >
                      </el-col>
                      <el-col :span="8">
                        <el-button size="mini" @click="getUnlockPeople(item.id)"
                          >查询解锁人数</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <!-- 操作 -->
                <div class="pickBox">
                  <p class="theTag">
                    <span>操作组</span>
                  </p>
                  <div class="content">
                    <el-button-group>
                      <!--                      <el-button-->
                      <!--                        size="mini"-->
                      <!--                        type="primary"-->
                      <!--                        icon="el-icon-edit"-->
                      <!--                        @click="handleService(item)"-->
                      <!--                        plain-->
                      <!--                        >绑定服务</el-button-->
                      <!--                      >-->
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="handleEdit(index + 1, item)"
                        plain
                        >编辑
                      </el-button>
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-coin"
                        @click="handleDetails(item)"
                        plain
                        >详情
                      </el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="handleDelete(index + 1, item.id)"
                        plain
                        >删除
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改机构" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action="/resource/v1/uploadFile"
            :headers="headersObj"
            :show-file-list="false"
            :on-success="editAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.avatar_url" :src="form.avatar_url" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.location"></el-input>
        </el-form-item>
        <el-form-item label="介绍">
          <el-input
            v-model="form.intro"
            type="textarea"
            placeholder="请输入机构简介"
            rows="3"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加弹出框 -->
    <el-dialog title="添加机构" v-model="addVisible" width="30%">
      <el-form ref="form" :model="addData" label-width="70px">
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action="/resource/v1/uploadFile"
            :headers="headersObj"
            accept=".png,.jpg,.gif,.jpeg,.PNG,.JPG,.GIF,.JPEG"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构名">
          <el-input v-model="addData.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="addData.phone" type="number"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="addData.location"></el-input>
        </el-form-item>
        <el-form-item label="介绍">
          <el-input
            type="textarea"
            placeholder="请输入机构简介"
            rows="3"
            v-model="addData.intro"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 选择服务人员 -->
    <el-dialog title="选择服务人员" v-model="selectHelper" width="30%">
      <el-select v-model="theHelper" filterable placeholder="请选择">
        <el-option
          v-for="item in helperList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="selectHelper = false">取 消</el-button>
          <el-button type="primary" @click="saveBinding">绑 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 绑定下级 -->
    <el-dialog title="选择下级" v-model="selectSubordinate" width="30%">
      <el-select
        v-model="theSubordinate"
        filterable
        placeholder="请选择"
        @change="subBureauChange"
      >
        <el-option
          v-for="item in bureauList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="selectSubordinate = false">取 消</el-button>
          <el-button type="primary" @click="saveBindingtheSub">绑 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 购买套餐 -->
    <el-dialog title="购买套餐" v-model="buyGoodsBox" width="30%">
      <!-- goods_category -->
      <el-form ref="form" :model="goodsData" label-width="150px">
        <el-form-item label="套餐类型" hidden>
          <el-select
            v-model="theGoodsType"
            filterable
            placeholder="请选择套餐类型"
            @change="changeGoodsType"
          >
            <el-option
              v-for="item in goodsTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计费模式" hidden>
          <el-select
            v-model="billing_mode_str"
            filterable
            placeholder="请选择计费模式"
          >
            <el-option
              v-for="item in billing_mode"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐">
          <el-select
            v-model="buyGoodsData.goods_id"
            filterable
            placeholder="请选择套餐"
            @change="changeGoods"
          >
            <el-option
              v-for="item in goodsData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐有效天数(天)">
          <el-date-picker
            v-model="tc_time"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            @change="selectPickerChange1"
          >
          </el-date-picker>

          {{ days }}天
        </el-form-item>
        <el-form-item
          label="套餐可用时长(小时)"
          v-if="
            buyGoodsData.goods_category === 'by_hours' ||
            buyGoodsData.goods_category === ''
          "
          hidden
        >
          <el-input type="number" v-model="buyGoodsData.hours"></el-input>
        </el-form-item>
        <el-form-item label="可用老师账号个数(个)" hidden>
          <el-input
            type="number"
            v-model="buyGoodsData.member_count"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐价格(元)">
          <el-input type="number" v-model="buyGoodsData.price"></el-input>
        </el-form-item>
        <el-form-item label="课时费用(元)">
          <el-input type="number" v-model="buyGoodsData.lesson_fee"></el-input>
        </el-form-item>
        <!-- 限制解锁次数，总共只能解锁多少次 -->
        <el-form-item label="单节课解锁次数">
          <el-input
            type="number"
            v-model="buyGoodsData.lesson_unlock_time_limit"
          ></el-input>
        </el-form-item>
        <!-- 一节课多长时间 -->
        <el-form-item label="课时时长(小时)">
          <el-input
            type="number"
            v-model="buyGoodsData.lesson_duration"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否为包年套餐">
          <el-switch
            v-model="buyGoodsData.disable_student_account"
            :active-value="true"
            :inactive-value="false"
          ></el-switch>
        </el-form-item>
        <!-- <el-form-item label="学生账号数">
                  <el-input type="number" v-model="buyGoodsData.student_account_limit"></el-input>
                </el-form-item>
                <el-form-item label="老师账号数">
                  <el-input type="number" v-model="buyGoodsData.teacher_account_limit"></el-input>
                </el-form-item> -->
        <!-- 周解锁次数, 每周能解锁多少次 -->
        <!-- <el-form-item label="周解锁次数(次)">
                  <el-input type="number" v-model="buyGoodsData.week_limit"></el-input>
                </el-form-item> -->
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="buyGoodsBox = false">取 消</el-button>
          <el-button type="primary" @click="buyGoodsSave">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 确认订单 -->
    <el-dialog
      title="请确认（确认后订单生效）"
      v-model="affirmOrder"
      width="30%"
    >
      <el-form ref="form" :model="orderData" label-width="150px">
        <el-form-item label="套餐名">
          <el-input
            type="text"
            v-model="orderData.goodsname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐有效天数(天)">
          <el-input type="number" v-model="orderData.days" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="套餐可用时长(小时)" v-if="theGoodsType === 'by_hours' || theGoodsType === ''">
                  <el-input type="number" v-model="orderData.hours"></el-input>
                </el-form-item> -->
        <!-- <el-form-item label="账号个数(个)">
                  <el-input type="number" v-model="orderData.member_count"></el-input>
                </el-form-item> -->
        <!-- <el-form-item
                            label="价格(元)"
                            v-if="theGoodsType !== 'by_lesson_fee'"
                        > -->
        <el-form-item label="价格(元)">
          <el-input type="number" v-model="orderData.price" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item
                            label="课时费用"
                            v-if="
                                theGoodsType === 'by_lesson_fee' || theGoodsType === ''
                            "
                        > -->
        <el-form-item label="课时费用">
          <el-input
            type="number"
            v-model="orderData.lesson_fee"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="课时时长(小时)">
          <el-input
            type="number"
            v-model="orderData.lesson_duration"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="学生账号数">
          <el-input
            type="number"
            v-model="orderData.student_account_limit"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="老师账号数">
          <el-input
            type="number"
            v-model="orderData.teacher_account_limit"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="是否为包年套餐">
          <!-- <el-input type="number" v-model="orderData.teacher_account_limit" disabled></el-input> -->
          <span> {{ orderData.disable_student_account ? "是" : "否" }}</span>
        </el-form-item>
        <!-- <el-form-item label="周解锁次数" >
                  <el-input type="number" v-model="orderData.week_limit" disabled></el-input>
                </el-form-item> -->
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="affirmOrder = false">取 消</el-button>
          <el-button type="primary" @click="affirmOrderSave">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 套餐续费 -->
    <el-dialog title="续费套餐" v-model="renewGoodsVal" width="30%">
      <el-form ref="form" :model="renewGoodsData" label-width="150px">
        <el-form-item label="套餐有效天数(天)">
          <!--          <el-input type="number" v-model="renewGoodsData.days"></el-input>-->
          <el-date-picker
            v-model="tc_time"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            @change="selectPickerChange"
          >
          </el-date-picker>

          {{ days }}天
        </el-form-item>
        <el-form-item label="套餐可用时长(小时)" hidden>
          <el-input type="number" v-model="renewGoodsData.hours"></el-input>
        </el-form-item>
        <el-form-item label="课时时长(小时)">
          <el-input
            type="number"
            v-model="renewGoodsData.lesson_duration"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="renewGoodsVal = false">取 消</el-button>
          <el-button type="primary" @click="renewGoodsSave">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 机构详情 -->
    <el-dialog title="机构详情" v-model="bureauDetails" width="50%">
      <div class="bureauDetails">
        <el-collapse v-model="bureauDetailsNames">
          <el-collapse-item title="基本信息" name="1">
            <div class="con-box">
              <el-row :gutter="20">
                <el-col :span="8">
                  <!-- avatar_id: 487
                                              avatar_url: "https://storage.shubenji.cn/GuoBao/resource/f2ab798fd27066e2.jpg?OSSAccessKeyId=LTAI4Fdpcm5Gwue9tEaZfiGS&Expires=1628597093&Signature=3%2FQ4P0ULxOnSFIaTZifbjfFmVDs%3D"
                                              create_time: "2021-08-08 20:24:56"
                                              goods_category: "by_lesson_fee"
                                              id: 43
                                              intro: "楕"
                                              location: "地址"
                                              name: "重阳教育"
                                              phone: "17821282021" -->
                  <span class="label">头像:</span>
                  <el-avatar
                    shape="square"
                    :size="40"
                    :src="detailsData.avatar_url"
                  ></el-avatar>
                </el-col>
                <el-col :span="8">
                  <span class="label">机构名称：</span>
                  <span class="text">{{ detailsData.name }}</span>
                </el-col>
                <!-- <el-col :span="8">
                            <span class="label">套餐类型：</span>
                            <span class="text" v-if="detailsData.goods_category == 'by_lesson_fee'">课时记费套餐</span>
                            <span class="text" v-if="detailsData.goods_category == 'by_member_count'">包年套餐</span>
                            <span class="text" v-if="detailsData.goods_category == 'by_hours'">计时套餐</span>
                            <span class="text" v-else>未知</span>
                          </el-col> -->
                <el-col :span="8">
                  <span class="label">手机号：</span>
                  <span class="text">{{ detailsData.phone }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">地址：</span>
                  <span class="text">{{ detailsData.location }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">介绍：</span>
                  <span class="text">{{ detailsData.intro }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">添加时间：</span>
                  <span class="text">{{ detailsData.create_time }}</span>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item title="资金信息" name="2">
            <div class="con-box">
              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="label">机构余额：</span>
                  <span class="text">{{ detailsData.balance }}元</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">已充值（不包括保证金）：</span>
                  <span class="text">{{ detailsData.total_credit }}元</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">保证金：</span>
                  <span class="text">{{ detailsData.security_credit }}元</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">退款：</span>
                  <span class="text">{{ detailsData.total_out }}元</span>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item title="限制信息" name="3">
            <div class="con-box">
              <el-row :gutter="20">
                <el-col :span="8">
                  <span class="label">周解锁限制次数：</span>
                  <span class="text">{{ detailsData.week_limit }}次</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">总剩余解锁课程次数：</span>
                  <span class="text">{{ detailsData.total_limit }}次</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">学生账户限制数：</span>
                  <span class="text">{{
                    detailsData.student_account_limit
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">老师账户限制数：</span>
                  <span class="text">{{
                    detailsData.teacher_account_limit
                  }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="label">剩余拍照次数：</span>
                  <span class="text">{{ photographAmount }} 次</span>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-button-group>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-shopping-bag-1"
            @click="buyGoods(detailsData.id, detailsData.goods_category)"
            >购买套餐
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-coin"
            @click="goTo('discount', { bureau_id: detailsData.id })"
            >折扣
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-coin"
            @click="topUp(detailsData.id, 'balance_security_deposit')"
            >缴纳保证金
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-coin"
            @click="openRestrictInfo(detailsData)"
          >
            修改机构限制
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-coin"
            @click="openPhotoAlert(detailsData)"
          >
            修改拍照次数限制
          </el-button>
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-coin"
            @click="topUp(detailsData.id)"
            >充值
          </el-button>
          <el-button
            size="medium"
            type="danger"
            icon="el-icon-coin"
            @click="topUp(detailsData.id, 'balance', 2)"
            >退款
          </el-button>
        </el-button-group>
      </div>
    </el-dialog>

    <el-dialog
      title="绑定服务"
      v-model="serviceStateAlert"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        选择服务
        <el-select v-model="service_intro_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        是否续费
        <el-radio v-model="is_renew" label="0">新购</el-radio>
        <el-radio v-model="is_renew" label="1">续费</el-radio>
      </div>
      <div style="width: 100%; margin-top: 20px; margin-left: 20px">
        <el-button
          type="primary"
          style="margin: 0 auto"
          @click="bindServiceClick"
          >绑定服务</el-button
        >
      </div>
    </el-dialog>
    <!-- 套餐信息 -->
    <el-dialog title="套餐信息" v-model="alertTcState" width="80%">
      <el-table
        :data="goodsInbureau"
        height="45vh"
        border
        class="table"
        ref="multipleTable"
        highlight-current-row
        header-cell-class-name="table-header"
        @current-change="handleBureauChange"
      >
        <el-table-column
          width="100"
          property="goods_name"
          label="套餐名"
        ></el-table-column>
        <!-- <el-table-column property="member_count" label="老师个数"></el-table-column> -->
        <el-table-column label="开始时间">
          <template #default="scope">
            {{
              scope.row.goods_category === "by_hours"
                ? ""
                : scope.row.start_time
            }}
          </template>
        </el-table-column>
        <el-table-column width="100" label="结束时间">
          <template #default="scope"
            >{{
              scope.row.goods_category === "by_hours" ? "" : scope.row.end_time
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column width="150" label="价格" property="lesson_fee">
                  <template #default="scope">{{
                  scope.row.is_yearly === 1
                  ? scope.row.fee + " [年费(元)]"
                  : scope.row.lesson_fee + " [课时计费(元)]"
                  }}</template>
                </el-table-column> -->
        <el-table-column label="年费(元)" property="fee"> </el-table-column>
        <el-table-column label="课时费用(元)" property="lesson_fee">
        </el-table-column>
        <!-- <el-table-column v-if="is_yearly" width="110" label="课时单价(元)" property="lesson_fee">
                                                  </el-table-column>
                                                  <el-table-column v-if="is_yearly" width="110" label="年费(元)" property="price">
                                                  </el-table-column> -->
        <el-table-column label="课时时长(H)" property="lesson_duration">
        </el-table-column>
        <el-table-column prop="disable_student_account" label="是否为包年套餐">
          <template #default="scope">
            <span
              v-if="scope.row.disable_student_account"
              style="color: #f56c6c"
              >是</span
            >
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column
          label="限制解锁次数(次)"
          property="lesson_unlock_time_limit"
        ></el-table-column>
        <!-- <el-table-column label="学生账号数" property="student_account_limit"></el-table-column> -->
        <!-- <el-table-column label="老师账号数" property="teacher_account_limit"></el-table-column> -->
        <!-- <el-table-column label="周解锁次数" property="week_limit"></el-table-column> -->

        <el-table-column label="操作" width="280" align="center">
          <template #default="scope">
            <el-button
              type="text"
              size="mini"
              icon="el-icon-edit"
              @click="renewGoods(scope.row.resource_id, scope.row)"
            >
              续费套餐
            </el-button>
            <el-button
              type="text"
              size="mini"
              icon="el-icon-edit"
              @click="editGoodsUnlockLimit(scope.row.resource_id)"
              >修改限制次数
            </el-button>
            <el-button
              type="text"
              size="mini"
              style="color: #f56c6c"
              icon="el-icon-delete"
              @click="deleteBureauGoods(scope.row)"
            >
              解绑套餐
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="goodsPage.page"
        :page-size="goodsPage.limit"
        :total="goodsPage.count"
        @current-change="handlePageChangeByGoods"
      ></el-pagination>
    </el-dialog>
    <!-- 套餐限制信息 -->
    <el-dialog title="套餐限制信息" v-model="restrictInfoDialog" width="40%">
      <el-form ref="form" :model="restrictInfoData" label-width="150px">
        <el-form-item label="周次数限制">
          <el-input
            type="number"
            v-model="restrictInfoData.week_limit"
          ></el-input>
        </el-form-item>
        <el-form-item label="总剩余解锁课时数量">
          <el-input
            type="number"
            v-model="restrictInfoData.total_limit"
          ></el-input>
        </el-form-item>
        <el-form-item label="学生账户限制数">
          <el-input
            type="number"
            v-model="restrictInfoData.student_account_limit"
          ></el-input>
        </el-form-item>
        <el-form-item label="老师账户限制数">
          <el-input
            type="number"
            v-model="restrictInfoData.teacher_account_limit"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="restrictInfoDialog = false">取 消</el-button>
          <el-button type="primary" @click="editRestrictInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <SetBureauTime :bureauId="bureau_id" ref="SetBureauTime"></SetBureauTime>
    <UnlockPeople :bureauId="bureau_id" ref="UnlockPeople"></UnlockPeople>
    <SetPhotoLimitation
      :bureauId="bureau_id"
      ref="SetPhotoLimitation"
      @getPhotographAmount="getPhotographAmount"
    ></SetPhotoLimitation>
  </div>
</template>

<script>
import SetBureauTime from "@/views/bureau/components/SetBureauTime";
import { fetchData } from "../../api/api";
import AdminLabel from "../../components/resource/adminTag/AdminLabel.vue";
import NotFound from "../../components/NotFound.vue";
import UnlockPeople from "./components/UnlockPeople.vue";
import SetPhotoLimitation from "./components/SetPhotoLimitation.vue";

export default {
  name: "basetable",
  computed: {
    groupsLessonPlan() {
      return (num, all) => {
        return Math.floor((num / all) * 100);
      };
    },
  },
  data() {
    return {
      photographAmount: 0,
      alertTcState: false,
      bureau_id: 0,
      options: [],
      service_intro_id: "",
      is_renew: "",
      serviceStateAlert: false,
      query: {
        page: 1,
        limit: 12,
        name: "",
      },
      selectTags: [],
      // reference: false,
      addData: {},
      bureauData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      selectHelper: false,
      selectSubordinate: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      imageUrl: "",
      imageId: 0,
      theHelpers: [],
      theHelper: "",
      theSubBureau: "",
      theSubordinates: [],
      theSubordinate: "",
      theBureau: 0,
      theGoodsType: "by_lesson_fee",
      helperList: [],
      bureauList: [],
      helpersInbureau: [],
      subsInBureauList: [],
      helperTable: false,
      selectedHelper: [],
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      buyGoodsData: {
        lesson_unlock_time_limit: 0,
      },
      buyGoodsBox: false,
      goodsData: [],
      affirmOrder: false,
      renewGoodsVal: false,
      renewGoodsData: {},
      orderId: 0,
      orderData: {},
      bureauUserData: {},
      renewGoodid: "",
      goodsInbureau: [],
      groupsInbureau: [],
      goodsPage: {
        bureau_id: "",
        page: 1,
        limit: 5,
        count: 0,
      },
      topUpMoney: 0,
      goodsTypeList: [
        {
          name: "计课时费套餐",
          value: "by_lesson_fee",
        },
        // {
        //     name: "计时套餐",
        //     value: "by_hours",
        // },
        // {
        //     name: "包年套餐",
        //     value: "by_member_count",
        // },
      ],
      // 计费模式
      billing_mode: [
        {
          name: "按课时费",
          value: "use_lesson_fee",
        },
        {
          name: "按年费",
          value: "use_year_fee",
        },
      ],
      billing_mode_str: "use_lesson_fee",
      bureauDetails: false,
      detailsData: {},
      bureauTags: [],
      unfoldBureau: [],
      end_time: "",
      tc_time: "",
      days: null,

      bureauDetailsNames: ["1", "2", "3"],
      // 套餐限制信息
      restrictInfoDialog: false,
      restrictInfoData: {},
    };
    // bureauDetails: false,
    // detailsData: {}
  },
  created() {
    this.getData();
    this.getServiceIntro();
  },
  components: {
    AdminLabel,
    NotFound,
    SetBureauTime,
    UnlockPeople,
    SetPhotoLimitation,
  },
  methods: {
    /**打开拍照限制 */
    openPhotoAlert(data) {
      this.bureau_id = data.id;
      this.$refs.SetPhotoLimitation.setAlertState = true;
      this.$refs.SetPhotoLimitation.amount = null;
    },
    /**查询解锁人数 */
    getUnlockPeople(id) {
      this.bureau_id = id;
      this.$refs.UnlockPeople.startTime = "";
      this.$refs.UnlockPeople.setAlertState = false;
      this.$refs.UnlockPeople.startData = "";
      this.$refs.UnlockPeople.countData = {};
      this.$refs.UnlockPeople.setAlertState = true;
    },
    /**设置时间*/
    setBureauTime(id) {
      this.bureau_id = id;
      this.$refs.SetBureauTime.setTimeState = true;
    },
    selectPickerChange() {
      let tc_time = new Date(this.tc_time).getTime();
      if (this.end_time < new Date().getTime()) {
        this.days = parseInt((tc_time - new Date().getTime()) / 86400000) + 1;
      } else {
        if (tc_time > this.end_time) {
          this.days = parseInt((tc_time - this.end_time) / 86400000) + 1;
        } else {
          this.days = 0;
        }
      }
    },
    selectPickerChange1() {
      let tc_time = new Date(this.tc_time).getTime();
      let new_time = new Date().getTime();
      console.log(tc_time, new_time);
      if (tc_time > new_time) {
        this.days = parseInt((tc_time - new_time) / 86400000) + 1;
      } else {
        this.days = 0;
      }
    },
    bindServiceClick() {
      // this.service_intro_id = 6;
      // this.is_renew = 0;
      // this.bureau_id = 38;
      if (this.service_intro_id == "") {
        this.$message.error("请选择服务");
        return;
      }

      if (this.is_renew == "") {
        this.$message.error("请选择类型");
        return;
      }
      let params = [
        {
          service_intro_id: this.service_intro_id,
          is_renew: this.is_renew,
          bureau_id: this.bureau_id,
        },
        "/edu/v1/generateServiceOrder",
        "GET",
      ];
      fetchData(...params).then((res) => {
        console.log(res);
        let order_id = res.data.order_id;
        let service_type_id = res.data.service_type;

        let params2 = [
          {
            order_id,
            service_type_id,
          },
          "/edu/v1/payUnlockService",
          "GET",
        ];
        fetchData(...params2).then(() => {});
      });
    },
    getServiceIntro() {
      let params = [{ service_type_id: 1 }, "/edu/v1/listServiceIntro", "GET"];
      fetchData(...params).then((res) => {
        console.log(res);
        this.options = res.data.list;
      });
    },
    handleService() {
      this.serviceStateAlert = true;
    },
    // 获取 easy-mock 的模拟数据
    getData() {
      this.query.tag_list = this.selectTags.toString();
      // this.query.tag_list = '';
      fetchData(this.query, "/bureau/v1/listBureau", "GET").then((res) => {
        this.bureauData = res.data.list;
        this.pageTotal = res.data.count || 0;
      });
      fetchData(
        { page: 1, limit: 999999 },
        "/bureau/v1/listHelper",
        "GET"
      ).then((res) => {
        this.helperList = res.data.list;
      });
      fetchData(
        { page: 1, limit: 999999 },
        "/bureau/v1/listBureau",
        "GET"
      ).then((res) => {
        console.log(res);
        this.bureauList = res.data.list;
      });
      fetchData(
        { page: 1, limit: 999999, category: "bureau" },
        "/bureau/v1.2/listTags",
        "GET"
      ).then((res) => {
        this.bureauTags = res.data.list;
      });
    },
    // 机构详情
    handleDetails(row) {
      console.log(row);
      this.bureauDetails = true;
      this.detailsData = row;
      this.getPhotographAmount();
    },
    //查询拍照次数
    getPhotographAmount() {
      fetchData(
        { bureau_id: this.detailsData.id },
        "/admin/v1/listCartoonAmount",
        "GET"
      ).then((res) => {
        this.photographAmount = res.data.amount;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
    // 删除操作
    handleDelete(index, id) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          fetchData({ id: id }, "/bureau/v1/deleteBureau", "GET").then(() => {
            this.$message.success("删除成功");
            this.bureauData.splice(index, 1);
          });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 添加操作
    bureauAdd() {
      this.addVisible = true;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      // this.$set(this.bureauData, this.idx, this.form);
      // this.form.avatar_id = this.imageId;
      fetchData(this.form, "/bureau/v1/editBureau", "POST").then(() => {
        this.$message.success(`修改第 ${this.idx + 1} 行成功`);
        this.editVisible = false;
      });
    },
    // 保存添加
    saveAdd() {
      this.addData.avatar_id = this.imageId;
      fetchData(this.addData, "/bureau/v1/createBureau", "POST").then((res) => {
        this.addVisible = false;
        this.bureauUserData = this.addData;
        this.bureauUserData.bureau_id = res.data;
        this.bureauUserData.name = this.addData.phone;
        this.bureauUserData.nickname = this.addData.name;
        this.bureauUserData.email = "a@qq.com";
        this.bureauUserData.slogan = "快乐每一天";

        fetchData(
          this.bureauUserData,
          "/admin/v1/createAdminUser",
          "POST"
        ).then(() => {
          this.$message.success(`添加成功`);
          this.bureauUserData = {};
        });
        this.addData = {};
        this.getData();
        // this.$set(this.bureauData, this.idx, this.form);
        // this.bureauData = res.list;
        // this.pageTotal = res.count || 50;
      });
    },
    saveBinding() {
      fetchData(
        { helper_id: this.theHelper, bureau_id: this.theBureau },
        "/admin/v1/helperBindingToBureau",
        "POST"
      ).then(() => {
        this.$message.success(`绑定成功`);
        this.selectHelper = false;
        this.getData();
      });
    },
    //绑定下级操作
    saveBindingtheSub() {
      fetchData(
        {
          sub_bureau_id: this.theSubordinate,
          bureau_id: this.theBureau,
        },
        "/admin/v1/bindSubBureau",
        "POST"
      ).then(() => {
        this.$message.success(`绑定成功`);
        this.selectSubordinate = false;
        this.getData();
      });
    },
    // 分页导航
    handlePageChange(val) {
      // this.$set(this.query, "page", val);
      this.query.page = val;
      this.getData();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageId = res.data.id;
    },
    editAvatarSuccess(res, file) {
      this.form.avatar_url = URL.createObjectURL(file.raw);
      this.form.avatar_id = res.data.id;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      // return isJPG && isLt2M;
      return isLt2M;
    },
    beforeBureau() {
      if (this.theBureau == 0) {
        this.$message({
          type: "warning",
          message: "请选择机构",
        });
        return false;
      }
      return true;
    },
    bindingHelper(id) {
      this.theBureau = id === 0 ? 0 : id;
      if (this.beforeBureau() === true) {
        this.selectHelper = true;
      }
    },
    // 绑定下级
    bindingSubordinate(id) {
      this.theBureau = id === 0 ? 0 : id;
      if (this.beforeBureau() === true) {
        this.selectSubordinate = true;
      }
    },
    subBureauChange(val) {
      if (this.theBureau === val) {
        this.$message({
          type: "warning",
          message: "不能绑定自己",
        });
        this.theSubordinate = "";
        return false;
      }
    },
    handleCurrentChange(row) {
      try {
        this.theBureau = row.id;
      } catch (error) {
        console.log(error);
      }
    },
    handleBureauChange(row) {
      try {
        this.renewGoodid = row.id;
      } catch (error) {
        console.log(error);
      }
    },
    showGroupsInbureau(id) {
      fetchData({ bureau_id: id }, "/admin/v1/listBureauInfo", "GET").then(
        (res) => {
          this.groupsInbureau = res.data;
        }
      );
    },
    showHelpersInbureau(id) {
      fetchData(
        { limit: 999999, bureau_id: id },
        "/bureau/v1/getHelperByBureauId",
        "GET"
      ).then((res) => {
        this.helpersInbureau = res.list;
      });
    },
    showSubBureau(id) {
      fetchData({ distributor_id: id }, "/bureau/v1.2/listSubs", "GET").then(
        (res) => {
          this.subsInBureauList = res.data.list;
        }
      );
    },
    showGoodsInbureau(id = "") {
      this.alertTcState = true;
      if (id !== "") this.theBureau = id;
      fetchData(
        {
          page: this.goodsPage.page,
          limit: this.goodsPage.limit,
          bureau_id: this.theBureau,
        },
        "/admin/v1/listBureauGoods",
        "GET"
      ).then((res) => {
        this.goodsPage.count = res.data.count;
        this.goodsInbureau = res.data.list;
      });
    },
    buyGoods(id, goodsType) {
      this.days = null;
      this.tc_time = "";
      this.theBureau = id;
      this.theGoodsType = goodsType;
      fetchData(
        // { limit: 999999, page: 1, goods_category: goodsType },
        { limit: 999999, page: 1 },
        "/edu/v1/listGoods",
        "GET"
      ).then((res) => {
        this.goodsData = res.data.list;
      });
      this.buyGoodsBox = true;
    },
    handlePageChangeByGoods(val) {
      this.goodsPage.page = val;
      this.showGoodsInbureau();
    },
    // 购买套餐
    buyGoodsSave() {
      // this.buyGoodsData.price =
      //   this.billing_mode_str === "use_year_fee" ? this.buyGoodsData.price : 0;
      // this.buyGoodsData.lesson_fee =
      //   this.billing_mode_str === "use_lesson_fee"
      //     ? this.buyGoodsData.lesson_fee
      //     : 0;
      this.buyGoodsData.bureau_id = this.theBureau;
      this.buyGoodsData.days = this.days;
      fetchData(this.buyGoodsData, "/admin/v1/bureauBuyGoods", "POST").then(
        (res) => {
          this.buyGoodsBox = this.bureauDetails = false;
          this.affirmOrder = true;
          this.orderId = res.data.order_id;
          this.orderData.goodsname = this.buyGoodsData.name;
          this.orderData.member_count = this.buyGoodsData.member_count;
          this.orderData.price = this.buyGoodsData.price;
          this.orderData.days = this.buyGoodsData.days;
          this.orderData.hours = this.buyGoodsData.hours;
          this.orderData.lesson_fee = this.buyGoodsData.lesson_fee;

          this.orderData.lesson_duration = this.buyGoodsData.lesson_duration;
          this.orderData.student_account_limit =
            this.buyGoodsData.student_account_limit;
          this.orderData.teacher_account_limit =
            this.buyGoodsData.teacher_account_limit;
          this.orderData.week_limit = this.buyGoodsData.week_limit;
          this.orderData.disable_student_account =
            this.buyGoodsData.disable_student_account;

          this.getData();
        }
      );
    },
    // 续费套餐
    renewGoods(id, item) {
      this.days = null;
      this.tc_time = "";
      this.renewGoodsVal = true;
      this.renewGoodsData.resource_id = id;
      this.end_time = new Date(item.end_time).getTime();
      console.log(this.end_time);
    },
    // 解绑套餐
    deleteBureauGoods(info) {
      var res = confirm(
        "您确定要解绑该资源吗？\n\n    被解绑机构：" +
          info.bureau_name +
          "\n    被解绑资源：" +
          info.goods_name
      );

      if (res) {
        var id = info.resource_id;
        fetchData(
          {
            bureau_resource_id: id,
          },
          "/admin/v1/deleteBureauResource",
          "GET"
        ).then((res) => {
          if (res.data === true) {
            this.$message.success("解绑成功");
            this.showGoodsInbureau();
          }
        });
      }
    },
    // 修改套餐限制次数
    editGoodsUnlockLimit(id) {
      var num = prompt("请输入新限制次数");
      if (!num) return;
      fetchData(
        {
          bureau_resource_id: id,
          lesson_unlock_time_limit: num,
        },
        "/admin/v1/editBureauResource",
        "GET"
      ).then((res) => {
        if (res.data === true) {
          this.$message.success("修改成功");
          this.showGoodsInbureau();
        }
      });
    },
    renewGoodsSave() {
      this.renewGoodsData.bureau_id = this.theBureau;
      this.renewGoodsData.days = this.days;
      fetchData(this.renewGoodsData, "/admin/v1/renewBureauGoods", "GET").then(
        () => {
          this.renewGoodsVal = false;
          this.renewGoodsData = {};
          this.$message.success(`续费成功`);
          this.showGoodsInbureau();
        }
      );
    },
    // 确认订单
    affirmOrderSave() {
      fetchData({ order_id: this.orderId }, "/admin/v1/applyOrder", "GET").then(
        () => {
          this.affirmOrder = false;
          this.$message.success(`确认成功`);
        }
      );
    },
    changeGoods(val) {
      this.goodsData.forEach((element) => {
        element.goods_id = element.id;
        element.lesson_fee = 100;
        if (element.id == val) this.buyGoodsData = element;
      });
    },
    changeGoodsType() {
      // this.theGoodsType = val;
      this.buyGoodsData = {};
      this.buyGoods(this.theBureau, this.theGoodsType);
    },
    // 机构充值(或扣除余额)
    /**
     *
     * @param {number} id
     * @param {string} type  balance 余额操作  balance_security_deposit 保证金操作
     * @param {number} action 1 添加动作   2扣除动作
     */
    topUp(id, type = "balance", action = 1) {
      let text = {
          balance: ["充值", "退款"],
          balance_security_deposit: ["缴纳保证金"],
        },
        money = prompt("请输入" + text[type][action - 1] + "的金额"),
        param = { bureau_id: id, money: money, category: type };
      if (!money) {
        this.$message.error("请输入金额");
        return false;
      }

      if (action == 2) {
        param.type = action;
        param.info = prompt("请输入退款原因");
        if (!param.info) {
          this.$message.error("请输入退款原因");
          return false;
        }
      }

      fetchData(param, "/admin/v1/addCredit", "GET").then((res) => {
        if (confirm("确实" + text[type][action - 1] + money + "元吗？")) {
          fetchData(
            { order_id: res.data },
            "/admin/v1/applyCreditOrder",
            "GET"
          ).then(() => {
            this.$message.success(text[type][action - 1] + `成功`);
            this.bureauDetails = false;
            this.getData();
          });
        }
      });
    },
    goTo(url, params) {
      this.$router.push({ path: "/", name: url, params: params });
    },
    // 点击机构时
    checkBureau(id) {
      this.bureau_id = id;
      let index = this.unfoldBureau.indexOf(id);
      if (-1 === index) {
        this.unfoldBureau.push(id);
      } else {
        this.unfoldBureau.splice(index, 1);
      }
    },
    // editWeekLimit(id) {
    //   let weekLimit = prompt("请输入机构每周的解锁限制次数"),
    //     param = { bureau_id: id, week_limit: weekLimit };
    //   if (!weekLimit) {
    //     this.$message.error("请输入周解锁次数");
    //     return false;
    //   }

    //   fetchData(
    //     param,
    //     "/admin/v1/updateBureauLimit",
    //     "GET"
    //   ).then(() => {
    //     this.$message.success(`修改成功`);
    //   });
    // },
    openRestrictInfo(data) {
      this.restrictInfoDialog = true;
      this.restrictInfoData.bureau_id = data.id;
      this.restrictInfoData.week_limit = data.week_limit;
      this.restrictInfoData.student_account_limit = data.student_account_limit;
      this.restrictInfoData.teacher_account_limit = data.teacher_account_limit;
      this.restrictInfoData.total_limit = data.total_limit;
    },
    // 修改套餐限制信息
    editRestrictInfo() {
      this.$confirm(
        "此操作将禁用该机构下所有账号(老师与学生), \n 机构可重新激活相应数量账号, 确定继续吗?",
        "危险操作",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          fetchData(
            this.restrictInfoData,
            "/admin/v1/updateBureauLimit",
            "GET"
          ).then(() => {
            this.restrictInfoDialog = false;
            let roles = ["teacher", "student"],
              id = this.restrictInfoData.bureau_id;
            roles.forEach((role) => {
              this.forbidAllUser(id, role);
            });
            this.$alert(
              "修改成功, 该机构下所有账号已禁用,请通知机构即时激活！！！",
              "提示",
              {
                confirmButtonText: "确定",
                callback: () => {
                  this.getData();
                  this.bureauDetails = false;
                },
              }
            );
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 禁用机构账号
    forbidAllUser(id, role, fun = () => {}) {
      fetchData(
        { bureau_id: id, role: role },
        "/admin/v1/forbidAllUser",
        "POST"
      ).then(() => {
        fun();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bureau-box {
  $time: 0.5s;

  .topPickBox {
    box-shadow: 0px 0px 0px 0px #ccc;
    margin-bottom: 0px;
    cursor: pointer;
    border-radius: 6px;
    transition: box-shadow $time, margin-bottom $time;

    .bureau-avatar {
      width: 65%;
      height: 100%;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
        border-radius: 50%;
        vertical-align: middle;
        aspect-ratio: 1/1;
        display: inline-block;
      }

      &::before {
        content: "";
        width: 0;
        height: 100%;
        display: inline-block;
        position: relative;
        vertical-align: middle;
      }
    }
  }

  .conPickBox {
    max-height: 0px;
    opacity: 0;
    transition: max-height $time, opacity $time;

    .pickBox {
      .theTag {
        $color: #ecf5ff;
        border-bottom: 1px solid #b3d8ff;

        span {
          font-size: 12px;
          display: inline-block;
          background-color: $color;
          color: #409eff;
          position: relative;
          height: 18px;
          left: 18px;

          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: -18px;
            border: 9px solid transparent;
            border-right-color: $color;
            border-bottom-color: $color;
          }

          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            border: 9px solid transparent;
            border-left-color: $color;
            border-bottom-color: $color;
          }
        }
      }

      .content {
        text-align: right;
      }
    }
  }

  .checked {
    box-shadow: 0px 4px 5px 0px #ccc;
    margin-bottom: 20px;

    & + .conPickBox {
      max-height: 230px;
      opacity: 1;
    }
  }

  .content {
    overflow: hidden;

    p {
      i {
        margin-right: 8px;
      }

      padding-left: 18px;
      margin-bottom: 3%;
      overflow: hidden;
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }

    .bureau-name {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 3%;
    }
  }
}

.bureauDetails {
  .con-box {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 8px;
  }

  .label {
    line-height: 3rem;
  }
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.table-td-avatar-url {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.form-avatar-url {
  display: block;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  width: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-upload--text {
  width: 178px;
}
</style>
