<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 充值记录
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <span class="demonstration">按机构查找</span>
                        <el-select
                            v-model="query.bureau_id"
                            placeholder="请选择机构"
                            filterable
                            @change="selectBureau"
                        >
                            <el-option label="全部" :value="null"></el-option>
                            <el-option
                                v-for="item in bureauList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <span class="demonstration">按类型查找</span>
                        <el-select
                            v-model="query.action_type"
                            placeholder="请选择类型"
                            filterable
                            @change="selectType"
                        >
                            <el-option label="全部" :value="''"></el-option>
                            <el-option
                                v-for="item in actionTypeList"
                                :key="item.val"
                                :label="item.name"
                                :value="item.val"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <span class="demonstration">按时间查找</span>
                        <el-date-picker
                            v-model="dateScope"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="YYYY 年 MM 月 DD 日"
                            :unlink-panels="true"
                            @change="changeDate"
                        >
                        </el-date-picker>
                    </el-col>
                </el-row>
                <!-- <span class="findBtn" @click="clickFind()">搜索</span> -->
            </div>
            <el-table
                :data="creditList"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <!-- <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                ></el-table-column> -->
                <el-table-column
                    prop="bureau_name"
                    label="机构名"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="credit"
                    label="充值数额(元)"
                    align="center"
                ></el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.status == 'done'"
                            >完成</el-tag
                        >
                        <el-tag
                            type="danger"
                            v-else-if="scope.row.status == 'cancelled'"
                            >关闭</el-tag
                        >
                        <el-tag
                            type="warning"
                            v-else-if="scope.row.status == 'revoken'"
                            >撤销</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="category" label="类别" align="center">
                    <template #default="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.action_type == 'withdraw'"
                            >消费</el-tag
                        >
                        <el-tag type="warning" v-else>充值</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="credit_category" label="金额用途" align="center">
                    <template #default="scope">
                        <el-tag
                            v-if="scope.row.credit_category == 'balance'"
                            >余额</el-tag
                        >
                        <el-tag v-else-if="scope.row.credit_category == 'balance_security_deposit'">保证金</el-tag>
                        <el-tag v-else-if="scope.row.credit_category == 'lesson_fee'">课时费用</el-tag>
                        <el-tag v-else-if="scope.row.credit_category == 'balance_security_withdraw'">保证金取出</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="credit_info" label="用途详情" align="center">
                    <template #default="scope">
                        <div style="text-align:left;">
                            <p>课程：{{ scope.row.lesson_fee_info_data.course_name ? scope.row.lesson_fee_info_data.course_name : "暂无" }}</p>
                            <p>课时：{{ scope.row.lesson_fee_info_data.lesson_name ? scope.row.lesson_fee_info_data.lesson_name : "暂无" }}</p>
                            <p>学生：{{ scope.row.lesson_fee_info_data.user_name ? scope.row.lesson_fee_info_data.user_name : "暂无" }}</p>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="admin_name"
                    label="操作管理员"
                    align="center"
                ></el-table-column> -->
                <el-table-column
                    prop="create_time"
                    label="充值时间"
                    align="center"
                ></el-table-column>
                <!-- <el-table-column label="操作" width="250" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-files"
                            @click="openDrawer(scope.$index, scope.row)"
                            >课时列表</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.$index, scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column> -->
            </el-table>

            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import DataUtils from "../utils/dateUtils";
// import Lesson from "../components/Lesson.vue";

export default {
    name: "coursetable",
    components: {
        // Lesson,
    },
    data() {
        return {
            query: {
                bureau_id: null,
                start_time: "2020-01-01",
                end_time: DataUtils.getDateStr(1, "YY-MM-DD"),
                page: 1,
                limit: 10,
                action_type: "",
            },
            creditList: [],
            actionTypeList: [
                { name: "消费", val: "withdraw" },
                { name: "充值", val: "deposit" },
            ],
            bureauList: [],
            theDate: "",
            dateScope: "",
            pageTotal: 10,
            DataUtils: DataUtils,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            this.tableData();
            this.bureauData();
        },
        selectBureau() {
            this.tableData();
        },
        selectType() {
            this.tableData();
        },
        tableData() {
            fetchData(this.query, "/bureau/v1.2/listCreditRec", "GET").then(
                (res) => {
                    this.creditList = res.data.list;
                    this.pageTotal = res.data.count;
                }
            );
        },
        bureauData() {
            fetchData(
                { page: 1, limit: 999999 },
                "/bureau/v1/listBureau",
                "GET"
            ).then((res) => {
                console.log(res);
                this.bureauList = res.data.list;
            });
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "pageIndex", val);
            this.query.page = val;
            this.getData();
        },
        changeDate() {
            this.query.start_time = DataUtils.formatDate(
                this.dateScope[0].getTime() / 1000,
                "YY-MM-DD"
            );
            this.query.end_time = DataUtils.formatDate(
                this.dateScope[1].getTime() / 1000,
                "YY-MM-DD"
            );
            this.tableData();
        },
        // clickFind() {
        // },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
.demonstration{
    margin-right: 1rem;
}
.findBtn{
    
}
</style>
