<template>
    <div>
        <p class="top">
            <span class="manageTag" @click="manageTag">{{ manageTagContent }}</span>
            <el-input :class="inputType" ref="input" v-model="inputValue" size="mini" placeholder="请输入内容"></el-input>
            <span class="addClick" :class="inputType" @click="inputDwon">确定</span>
        </p>
        <hr />
        <Tag
            :tags="tags"
            :isClick="isClick"
            :isEdit="true"
            @addTags="addTags"
            @selectTag="selectTag"
            @dangerTag="dangerTag"
            @editTag="editTag"
        ></Tag>
    </div>
</template>

<script>
import Tag from "./Tag.vue";
import { fetchData } from "../../../api/api";

export default {
    name: "LabelDialog",
    components: {
        Tag,
    },
    computed: {
        inputType() {
            return this.isShowTagInput ? 'show' : '';
        }
    },
    props: ["category","categoryId"],
    emits: ["getCheckedTags"],
    data() {
        return {
            isClick: true,
            isShowTagInput: false,
            manageTagContent: "管理标签",
            inputValue: "",
            theInputOperate: "add", // add,  edit
            editTagId: 0,
            tags: [],
        };
    },
    created() {
        this.getTags();
    },
    methods: {
        getTags() {
            fetchData({category: this.category, other_id: this.categoryId, require_unbound_tags: 1}, "/bureau/v1.2/listOtherTags", "GET").then((res) => {
                this.tags = res.data;
            });
        },
        addTags() {
            this.isShowTagInput = true;
            this.$refs.input.focus();
        },
        inputDwon() {
            this.addTags();
            if (this.theInputOperate === "add") {
                fetchData({type: 0, name: this.inputValue, category: this.category}, "/bureau/v1.2/addTag", "POST").then(() => {
                    this.getTags();
                    this.$message.success("添加成功");
                    this.isShowTagInput = false;
                });
            }
            if (this.theInputOperate === "edit") {
                fetchData({id: this.editTagId, name: this.inputValue}, "/bureau/v1.2/editTag", "GET").then(() => {
                    this.getTags();
                    this.$message.success("修改成功");
                    this.isShowTagInput = false;
                });
            }
        },
        editTag(tag) {
            this.addTags();
            this.inputValue = tag.name;
            this.editTagId = tag.id;
            this.theInputOperate = 'edit';
        },
        selectTag(tags) {
            this.$emit("getCheckedTags", tags);
        },
        manageTag() {
            this.isClick = !this.isClick;
            this.manageTagContent = this.isClick ? "管理标签" : "返回";
        },
        dangerTag(id) {
            fetchData({id: id}, "/bureau/v1.2/deleteTag", "GET").then(() => {
                this.getTags();
                this.$message.success("删除成功");
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.top {
    .manageTag {
        padding: 3px 5px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #0099CC;
        color: #fff;
    }
    .el-input {
        margin-left: 5px;
        padding: 5px;
        border-radius: 5px;
        outline-color: #f99;
        width: 0px;
        opacity: 0;
        transition: width 0.5s, opacity 0.5s;
        &.show {
            width: 40%;
            opacity: 1;
        }
    }
    .addClick {
        margin-left: 5px;
        padding: 5px 5px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #99CC66;
        color: #fff;
        opacity: 0;
        transition: opacity 0.5s;
        &.show {
            opacity: 1;
        }
    }
}
hr {
    margin: 5px 0;
}
</style>