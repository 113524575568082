<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 服务人员列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="helperAdd"
                    >添加服务人员</el-button
                >
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    class="right"
                    @click="handleSearch"
                    >搜索</el-button
                >
                <el-input
                    v-model="query.name"
                    placeholder="服务人员名"
                    class="handle-input mr10 right"
                ></el-input>
            </div>
            <el-table
                :data="helperData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="id"
                    label="ID"
                    width="55"
                    align="center"
                ></el-table-column>
                <el-table-column label="头像" align="center">
                    <template #default="scope">
                        <el-avatar
                            shape="square"
                            :size="40"
                            :src="scope.row.avatar_url"
                        ></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column label="微信二维码">
                    <template #default="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.wechat_url"
                            :preview-src-list="[scope.row.wechat_url]"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="intro" label="介绍"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.$index, scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改服务人员" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="头像">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="editAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img
                            v-if="form.avatar_url"
                            :src="form.avatar_url"
                            class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="服务人员名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="微信二维码">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="editWechatSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <el-image
                            v-if="form.wechat_url"
                            :src="form.wechat_url"
                        ></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input
                        v-model="form.intro"
                        type="textarea"
                        placeholder="请输入服务人员简介"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加服务人员" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="70px">
                <el-form-item label="头像">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="addAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img
                            v-if="addData.avatar_url"
                            :src="addData.avatar_url"
                            class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="服务人员名">
                    <el-input v-model="addData.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="addData.phone"></el-input>
                </el-form-item>
                <el-form-item label="微信二维码">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="addWechatSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <el-image
                            v-if="addData.wechat_url"
                            :src="addData.wechat_url"
                        ></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input
                        type="textarea"
                        placeholder="请输入服务人员简介"
                        rows="3"
                        v-model="addData.intro"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
export default {
    name: "helper",
    data() {
        return {
            query: {
                address: "",
                name: "",
                page: 1,
                limit: 10,
            },
            addData: {},
            helperData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            addVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            imageUrl: "",
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(this.query, "/bureau/v1/listHelper", "GET").then(
                (res) => {
                    console.log(res);
                    this.helperData = res.data.list;
                    this.pageTotal = res.data.count || 0;
                }
            );
        },
        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            this.query.name = "";
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { helper_id: id },
                        "/bureau/v1/deleteHelper",
                        "GET"
                    ).then((res) => {
                        console.log(res);
                        this.$message.success("删除成功");
                        this.helperData.splice(index, 1);
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        helperAdd(index, row) {
            this.idx = index;
            this.form = row;
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            fetchData(this.form, "/bureau/v1/editHelper", "POST").then(
                (res) => {
                    console.log(res);
                    this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                    this.editVisible = false;
                    this.form = {};
                }
            );
        },
        // 保存添加
        saveAdd() {
            fetchData(this.addData, "/bureau/v1/createHelper", "POST").then(
                (res) => {
                    console.log(res);
                    this.$message.success(`添加成功`);
                    this.addVisible = false;
                    this.getData();
                    this.addData = {};
                }
            );
        },
        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        editAvatarSuccess(res, file) {
            this.form.avatar_url = URL.createObjectURL(file.raw);
            this.form.avatar_id = res.data.id;
        },
        editWechatSuccess(res, file) {
            this.form.wechat_url = URL.createObjectURL(file.raw);
            this.form.wechat_id = res.data.id;
        },
        addAvatarSuccess(res, file) {
            this.addData.avatar_url = URL.createObjectURL(file.raw);
            this.addData.avatar_id = res.data.id;
        },
        addWechatSuccess(res, file) {
            this.addData.wechat_url = URL.createObjectURL(file.raw);
            this.addData.wechat_id = res.data.id;
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
                // this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}
</style>
