<template>
	<div style="text-align: center;">
		<img src="../assets/img/nothing.png" alt="" style="width: 10%;margin:0 auto;">
		<p style="font-size: 20px; color: #666;">~~{{ text }}~~</p>
	</div>
</template>
<script>
export default {
	name: "NowFound",
	props: ["text"]
}
</script>