<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 课程列表
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="courseAdd"
          >添加课程</el-button
        >
        <!--                <el-button icon="el-icon-s-operation" type="primary" @click="cloneListVisble = true"-->
        <!--                >克隆记录-->
        <!--                </el-button-->
        <!--                >-->
        <el-button
          type="primary"
          icon="el-icon-search"
          class="right"
          @click="handleSearch"
          >搜索</el-button
        >
        <el-input
          v-model="query.search_name"
          placeholder="课程名"
          class="handle-input mr10 right"
        ></el-input>
      </div>
      <el-table
        :data="courseData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="课程名称"></el-table-column>
        <el-table-column prop="intro" label="课程介绍"></el-table-column>
        <!-- <el-table-column label="课程封面">
                    <template #default="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.image_url"
                            :preview-src-list="[scope.row.image_url]"
                        ></el-image>
                    </template>
                </el-table-column> -->
        <el-table-column label="课程标签" width="120">
          <template #default="scope">
            <admin-label
              category="course"
              :categoryId="scope.row.id"
              style="font-size: 12px"
            ></admin-label>
          </template>
        </el-table-column>

        <el-table-column label="课程图片组" width="160">
          <template #default="scope">
            <!-- <admin-label
              category="course"
              :categoryId="scope.row.id"
              style="font-size: 12px"
            ></admin-label> -->
            <u
              v-if="scope.row.edu_cartoon_group_id !== 0"
              @click="handleCourseGroun(scope.row)"
              style="color: blue; cursor: pointer"
              >{{ scope.row.edu_cartoon_group_name }}</u
            >
            <el-button
              v-if="scope.row.edu_cartoon_group_id === 0"
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="handleCourseGroun(scope.row)"
            ></el-button>
          </template>
        </el-table-column>

        <el-table-column prop="category" label="类别" width="100">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.category == 1"
              >普通课程</el-tag
            >
            <el-tag type="warning" v-else-if="scope.row.category == 2"
              >教师训练用课程</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="category" label="是否克隆" width="100">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.category == 3">克隆</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="课程类型" width="100">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.type == 'scratch'"
              >scratch</el-tag
            >
            <el-tag type="success" v-if="scope.row.type == 'python'"
              >python</el-tag
            >
            <el-tag type="success" v-if="scope.row.type == 'LEGO'">乐高</el-tag>
            <el-tag type="success" v-if="scope.row.type == 'robot'"
              >机器人</el-tag
            >
            <el-tag type="success" v-if="scope.row.type == 'scratchJr'"
              >scratchJr</el-tag
            >
            <el-tag type="success" v-if="scope.row.type == 'guodong'"
              >guodong</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="添加时间"
          width="100"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑
            </el-button>
            <el-button
              type="text"
              icon="el-icon-files"
              @click="openDrawer(scope.$index, scope.row)"
              >课时列表
            </el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row.id)"
              >删除
            </el-button>

            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleClone(scope.$index, scope.row)"
              >克隆数据
            </el-button>
            <!-- <el-popconfirm :title="'确认克隆一分新课程《'+ scope.row.name +'（副本）》到您的课程列表中吗？'"
                            @confirm="cloneCourse(scope.$index, scope.row.id)">
                            <template #reference>
                            <el-button
                                type="text"
                                class="warning"
                                icon="el-icon-document-copy"
                                >克隆</el-button>
                            </template>
                        </el-popconfirm>
                         -->
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog title="修改课程" v-model="editVisible" width="40%">
      <el-form ref="form" :model="form" label-width="160px">
        <el-form-item label="课程名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="课程封面">
          <el-upload
            class="avatar-uploader"
            action="/resource/v1/uploadFile"
            :headers="headersObj"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image v-if="form.image_url" :src="form.image_url"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option
              v-for="item in typeOption"
              :key="item.sign"
              :label="item.title"
              :value="item.sign"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="课程介绍">
          <el-input
            v-model="form.intro"
            type="textarea"
            placeholder="请输入课程介绍"
            rows="3"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加弹出框 -->
    <el-dialog title="添加课程" v-model="addVisible" width="40%">
      <el-form ref="form" :model="addData" label-width="160px">
        <el-form-item label="课程名称">
          <el-input v-model="addData.name"></el-input>
        </el-form-item>
        <el-form-item label="课程封面">
          <el-upload
            class="avatar-uploader"
            action="/resource/v1/uploadFile"
            :headers="headersObj"
            :show-file-list="false"
            :on-success="addAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image
              v-if="addData.image_url"
              :src="addData.image_url"
            ></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="addData.type" placeholder="请选择类型">
            <el-option
              v-for="item in typeOption"
              :key="item.sign"
              :label="item.title"
              :value="item.sign"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-radio v-model="addData.category" :label="1">普通课程</el-radio>
          <el-radio v-model="addData.category" :label="2"
            >教师训练用课程</el-radio
          >
        </el-form-item>
        <el-form-item label="课程介绍">
          <el-input
            v-model="addData.intro"
            type="textarea"
            placeholder="请输入课程介绍"
            rows="3"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 克隆记录 -->
    <el-dialog title="克隆列表" v-model="cloneListVisble" width="80%">
      <clone-list
        v-if="cloneListVisble"
        :courseItem="courseItem"
        @handleCloseClone="handleCloseClone"
        @cloneSuccess="cloneSuccess"
      ></clone-list>
    </el-dialog>

    <!-- 课时列表 -->
    <el-drawer
      title="课时列表"
      v-model="drawer"
      :direction="direction"
      :destroy-on-close="true"
      size="75%"
      style="overflow: scroll"
    >
      <Lesson :course_id="form.id"></Lesson>
    </el-drawer>

    <el-dialog title="图片组列表" width="60%" v-model="cgState">
      <CourseGroup
        :courseItem="courseGroupItem"
        :cgState="cgState"
        style="font-size: 12px"
        @refreshData="getData"
      ></CourseGroup
    ></el-dialog>
  </div>
</template>

<script>
import { fetchData } from "../api/api";
import Lesson from "../components/Lesson.vue";
import AdminLabel from "../components/resource/adminTag/AdminLabel.vue";
import CloneList from "../components/course/CloneList.vue";
import CourseGroup from "../components/resource/course/CourseGroup";

export default {
  name: "coursetable",
  components: {
    Lesson,
    AdminLabel,
    CloneList,
    CourseGroup,
  },
  data() {
    return {
      cgState: false,
      query: {
        address: "",
        search_name: "",
        page: 1,
        limit: 10,
      },
      lessonQuery: {
        page: 1,
        limit: 10,
      },
      addData: {
        lesson_duration: 120,
      },
      courseData: [],
      course_id: 0,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      cloneListVisble: false,
      pageTotal: 0,
      lessonPageTotal: 0,
      form: {
        lesson_duration: 120,
      },
      idx: -1,
      id: -1,
      imageUrl: "",
      imageId: 0,
      drawer: false,
      innerDrawer: false,
      direction: "btt",
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      typeOption: [
        {
          sign: "scratch",
          title: "Scratch",
        },
        {
          sign: "python",
          title: "python",
        },
        {
          sign: "LEGO",
          title: "乐高",
        },
        {
          sign: "robot",
          title: "机器人",
        },
        {
          sign: "scratchJr",
          title: "scratchJr",
        },
        {
          sign: "guodong",
          title: "guodong",
        },
      ],
      courseItem: {},

      courseGroupItem: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleCourseGroun(item) {
      this.cgState = true;
      this.courseGroupItem = item;
    },
    cloneSuccess() {
      this.cloneListVisble = false;
      // this.$message.success('克隆成功，请查看克隆数据细节')
    },
    handleCloseClone() {
      this.cloneListVisble = false;
    },
    handleClone(index, row) {
      console.log(index);
      this.courseItem = row;
      this.cloneListVisble = true;
    },
    // 获取 easy-mock 的模拟数据
    getData() {
      fetchData(this.query, "/admin/v1/listCourse", "GET").then((res) => {
        this.courseData = res.data.list;
        this.pageTotal = res.data.count || 0;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
    // 删除操作
    handleDelete(index, id) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          fetchData({ course_id: id }, "/admin/v1/deleteCourse", "GET").then(
            () => {
              this.$message.success("删除成功");
              this.courseData.splice(index, 1);
            }
          );
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 添加操作
    courseAdd(index, row) {
      this.idx = index;
      this.form = row;
      this.addVisible = true;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 打开课时列表
    openDrawer(index, row) {
      this.idx = index;
      this.form = row;
      this.drawer = true;
    },
    // 保存编辑
    saveEdit() {
      this.form.course_id = this.form.id;
      this.form.image_id = this.imageId ? this.imageId : this.form.image_id;
      fetchData(this.form, "/admin/v1/editCourse", "POST").then(() => {
        this.$message.success(`修改第 ${this.idx + 1} 行成功`);
        this.editVisible = false;
        this.getData();
      });
    },
    // 保存添加
    saveAdd() {
      this.addData.image_id = this.imageId;
      fetchData(this.addData, "/admin/v1/createCourse", "POST").then(() => {
        this.$message.success(`添加成功`);
        this.addVisible = false;
        this.getData();
        this.addData = {};
      });
    },
    // 分页导航
    handlePageChange(val) {
      // this.$set(this.query, "pageIndex", val);
      this.query.page = val;
      this.getData();
    },
    handleAvatarSuccess(res, file) {
      this.imageId = res.data.id;
      this.form.image_url = URL.createObjectURL(file.raw);
    },
    addAvatarSuccess(res, file) {
      this.imageId = res.data.id;
      this.addData.image_url = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 克隆
    cloneCourse(index, course_id) {
      fetchData({ course_id: course_id }, "/edu/v1/cloneCourse", "GET").then(
        () => {
          this.$message.success(`修改第 ${this.idx + 1} 行成功`);
          this.editVisible = false;
          this.getData();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.warning {
  color: #e6a23c;
}

.mr10 {
  margin-right: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.table-td-avatar-url {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.form-avatar-url {
  display: block;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  width: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

::v-deep .el-upload--text {
  width: 120px;
  height: 120px;
}

::v-deep .el-drawer {
  overflow: scroll;
}
</style>
