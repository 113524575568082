<template>
  <div>
    <div>
      <el-dialog
        title="修改机构拍照限制次数"
        v-model="setAlertState"
        width="40%"
        :before-close="handleClose"
      >
        <div>
          <div style="display: flex; align-items: center">
            <div style="width: 100px" >限制几次：</div>
            <el-input type="number" v-model="amount" style="width: 400px"></el-input
            >（原先基础上增加）
          </div>
        </div>

        <div style="display: flex; justify-content: center; margin-top: 30px">
          <el-button type="primary" @click="onSubmit">添加限制</el-button>
          <el-button style="margin-left: 16px" @click="setAlertState = false"
            >取消</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

  <script>
import { fetchData } from "../../../api/api";
export default {
  props: {
    bureauId: {},
  },
  data() {
    return {
      setAlertState: false,
      amount: null,

      startTime: "",
      startData: "",
      countData: {},
    };
  },
  methods: {
    onSubmit() {
      if (this.amount !== null) {
        const params = [
          {
            amount: this.amount,
            bureau_id: this.bureauId,
          },
          "/admin/v1/addCartoonAmount",
          "POST",
        ];
        fetchData(...params).then(() => {
          this.$message.success("设置成功");
          this.setAlertState = false;
          this.$emit("getPhotographAmount");
        });
      } else {
        this.$message.error("请输入限制次数");
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
</style>
