<template>
    <div>
        <ul class="tags-list">
            <li
                v-for="(item, index) in tags"
                :key="index"
                :class="checkedList.indexOf(item.id) > -1 ? 'pitchOn' : ''"
                @click="isClick ? selectTagFun(item) : ''"
            >
                {{ item.name }}
                <i
                    v-if="isEdit && !isClick"
                    class="el-icon el-icon-edit"
                    @click="$emit('editTag', item)"
                ></i>
                <el-popconfirm
                    v-if="!isClick"
                    confirmButtonText="确定"
                    cancelButtonText="取消"
                    icon="el-icon-info"
                    iconColor="red"
                    :title="dangerTitle"
                    @confirm="$emit('dangerTag', item.id)"
                >
                    <template #reference>
                        <i
                            class="el-icon el-icon-close"
                        ></i>
                    </template>
                </el-popconfirm>
            </li>
            <li style="padding: 3px 6px" @click="$emit('addTags')">
                <i class="el-icon el-icon-plus"></i>
            </li>
            <p style="clear: both"></p>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Tag",
    components: {},
    computed: {
        dangerTitle() {
            return "确认"+ (this.isBind ? "解绑" : "删除") +"吗？";
        }
    },
    props: ["tags", "isClick", "isEdit", "isBind"],
    emits: ["addTags","dangerTag","selectTag"],
    data() {
        return {
            checkedList: [],
        };
    },
    methods: {
        selectTagFun(item) {
            let index = this.checkedList.indexOf(item.id);
            if (index >= 0) {
                this.checkedList.splice(index, 1);
            } else {
                this.checkedList.push(item.id);
            }
            this.$emit("selectTag", this.checkedList);
        },
    },
};
</script>

<style lang="scss" scoped>
.tags-list {
    list-style-type: none;
    li {
        cursor: pointer;
        float: left;
        margin-right: 10px;
        background: #e6e6e6;
        padding: 2px 10px;
        border-radius: 5px;
        color: #003366;
        margin-bottom: 5px;
        user-select: none;
        i {
            cursor: pointer;
            margin-left: 4px;
            &.el-icon-close {
                color: #f56c6c;
            }
            &.el-icon-edit {
                color: #0099cc;
            }
            &.el-icon-plus {
                color: #009966;
            }
        }
        &::after {
            content: "";
            clear: both;
        }
        &.pitchOn {
            outline: 1px solid #999;
            box-shadow: 0px 5px 4px 0px #999;
        }
    }
}
</style>