<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 提现列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <span style="margin-right: 2rem; color: #666;">状态筛选</span>
                <el-select v-model="query.status" placeholder="选择提现状态" @change="searchStatus">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="分销提现" name="distribution">
                    <el-table :data="tableData" border class="table" ref="multipleTable"
                        header-cell-class-name="table-header">
                        <el-table-column prop="distributor_name" label="经销人"></el-table-column>
                        <el-table-column prop="money" label="提现的金额(元)"></el-table-column>
                        <el-table-column prop="status" label="提现状态">
                            <template #default="scope">
                                <el-tag type="warning" v-if="scope.row.status == 1">未审核</el-tag>
                                <el-tag type="success" v-else-if="scope.row.status == 2">已通过</el-tag>
                                <el-tag type="danger" v-else>已拒绝</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号"></el-table-column>
                        <el-table-column label="添加时间">
                            <template #default="scope">
                                {{ DataUtils.formatDate(scope.row.create_time) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="250" align="center">
                            <template #default="scope">
                                <reference></reference>
                                <el-popover placement="bottom" title="操作组" width="200" trigger="click">
                                    <el-button-group>
                                        <el-button type="success" icon="el-icon-circle-check"
                                            @click="pass(scope.row.id)">同意
                                        </el-button>
                                        <el-button type="danger" @click="refuse(scope.row.id)">拒绝<i class="
                                            el-icon-circle-close
                                        "></i></el-button>
                                    </el-button-group>
                                    <template v-slot:reference>
                                        <el-button type="text" icon="el-icon-edit" :disabled="scope.row.status !== 1">审核
                                        </el-button>
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="保证金提现" name="earnest">
                    <el-table :data="tableEarnestData" border class="table" ref="multipleTable"
                        header-cell-class-name="table-header">
                        <el-table-column prop="bureau_name" label="机构"></el-table-column>
                        <el-table-column prop="bureau_phone" label="机构手机号"></el-table-column>
                        <el-table-column prop="money" label="保证金"></el-table-column>
                        <el-table-column prop="status" label="提现状态">
                            <template #default="scope">
                                <div v-if="scope.row.is_checked == 1">
                                    <el-tag type="success" v-if="scope.row.is_ok == 1">已通过</el-tag>
                                    <el-tag type="danger" v-else>已拒绝</el-tag>
                                </div>
                                <div v-else>
                                    <el-tag type="warning">未审核</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="添加时间">
                            <template #default="scope">
                                {{ DataUtils.formatDate(scope.row.create_Time) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="250" align="center">
                            <template #default="scope">
                                <reference></reference>
                                <el-popover placement="bottom" title="操作组" width="200" trigger="click">
                                    <el-button-group>
                                        <el-button type="success" icon="el-icon-circle-check"
                                            @click="earnestPass(scope.row.id)">同意
                                        </el-button>
                                        <el-button type="danger" @click="earnestRefuse(scope.row.id)">拒绝<i class="
                                            el-icon-circle-close
                                        "></i></el-button>
                                    </el-button-group>
                                    <template v-slot:reference>
                                        <el-button type="text" icon="el-icon-edit"
                                            :disabled="scope.row.is_checked == 1">审核
                                        </el-button>
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.limit" :total="pageTotal" @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import DataUtils from "../utils/dateUtils";

export default {
    name: "coursetable",
    components: {},
    data() {
        return {
            activeName: "distribution",
            query: {
                status: 0,
                page: 1,
                limit: 10,
            },
            statusList: [
                { label: "全部", value: 0 },
                { label: "审核中", value: 1 },
                { label: "审核成功", value: 2 },
                { label: "审核失败", value: 3 },
            ],
            tableData: [],
            totleNum: 0,
            errorText: '',
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
            DataUtils: DataUtils,

            tableEarnestData: []
        };
    },
    created() {
        this.getData();
        this.getEarnestData();
    },
    methods: {
        getData() {
            fetchData(this.query, "/distribution/v1/listDistributionRecs", "GET").then((res) => {
                this.tableData = res.data.list;
                this.pageTotal = res.data.count || 0;
            });
        },
        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            switch (this.activeName) {
                case "distribution":
                    this.getData();
                    break;
                case "earnest":
                    this.getEarnestData();
                    break;
            }
            this.getData();
        },
        refuse(id) {
            let theRefuse = prompt('请输入您的拒绝理由');
            if (!theRefuse) {
                this.$message.error(`请输入理由`);
                return false;
            }

            fetchData({ rec_id: id, status: 3, error: theRefuse }, "/distribution/v1/reviewRec", "GET").then(() => {
                this.$message.success(`操作成功`);
                this.getData();
            });
        },
        pass(id) {
            fetchData({ rec_id: id, status: 2 }, "/distribution/v1/reviewRec", "GET").then(() => {
                this.$message.success(`操作成功`);
                this.getData();
            });
        },
        searchStatus() {
            this.getData();
        },

        getEarnestData() {
            fetchData(this.query, "/bureau/v1.2/listSecCheckRec", "GET").then((res) => {
                this.tableEarnestData = res.data.list;
                this.pageTotal = res.data.count || 0;
            });
        },
        earnestRefuse(id) {
            let theRefuse = prompt('请输入您的拒绝理由');
            if (!theRefuse) {
                this.$message.error(`请输入理由`);
                return false;
            }

            fetchData({ sec_rec_id: id, is_ok: 0, comment: theRefuse }, "/bureau/v1.2/WithdrawSecurityDeposit", "POST").then(() => {
                this.$message.success(`操作成功`);
                this.getEarnestData();
            });
        },
        earnestPass(id) {
            fetchData({ sec_rec_id: id, is_ok: 1 }, "/bureau/v1.2/WithdrawSecurityDeposit", "POST").then(() => {
                this.$message.success(`操作成功`);
                this.getEarnestData();
            });
        },
        handleClick() {
            this.query = {
                status: 0,
                page: 1,
                limit: 10
            }
            switch (this.activeName) {
                case "distribution":
                    this.getData();
                    break;
                case "earnest":
                    this.getEarnestData();
                    break;
            }
        }

    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}

::v-deep .el-drawer {
    overflow: scroll;
}
</style>
