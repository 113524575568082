<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="lessonAdd">添加课时
                </el-button>
                <el-button type="primary" icon="el-icon-search" class="right" @click="handleSearch">搜索
                </el-button>
                <el-input v-model="query.name" placeholder="课时名" class="handle-input mr10 right"></el-input>
            </div>
            <el-table :data="lessonData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                :default-sort="{ prop: 'pos' }" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="name" label="课时名称"></el-table-column>
                <el-table-column label="课时封面">
                    <template #default="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.image_url"
                            :preview-src-list="[scope.row.image_url]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="知识点">
                    <template #default="scope">
                        <span v-for="item in scope.row.points" :key="item" style="margin-right: 10px">{{ item.point_name
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="添加时间"></el-table-column>
                <el-table-column label="排序" prop="pos">
                    <template #default="scope">
                        <ConfirmableInput v-model="scope.row.pos" @change="blurPos(scope.row)"></ConfirmableInput>
                        <!-- <el-input style="width: 72px" :modalValue="scope.row.pos"></el-input>
                        <el-button @click="blurPos(scope.row)">修改</el-button> -->
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-button type="text" icon="el-icon-edit" @click="bindResource(scope.row)">绑定课时资源
                        </el-button>
                        <el-button type="text" icon="el-icon-edit" @click="bindStudentResource(scope.row)">学生课时资源
                        </el-button>
                        <el-button type="text" icon="el-icon-delete" class="red"
                            @click="handleDelete(scope.$index, scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改课时" v-model="editVisible" width="70%" :before-close="handleClose">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="课时名称">
                    <el-input type="textarea" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="课时封面">
                    <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <el-image v-if="form.image_url" :src="form.image_url"></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="知识点组">
                    <el-select v-model="lesson_group_id" placeholder="请选择">
                        <el-option v-for="item in lessonGroupList" :key="item.group_id" :label="item.group_name"
                            :value="item.group_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="绑定教案">
                    <el-select v-model="plan_act_id" placeholder="请选择">
                        <el-option v-for="item in planData" :key="item.act_id" :label="item.act_name"
                            :value="item.act_id">
                        </el-option>
                    </el-select>
                    <el-button type="primary" @click="bindLessonAct">绑定教案</el-button>
                    <el-button type="warning" @click="unbindLessonAct">解绑教案</el-button>
                </el-form-item>
                <el-form-item label="课时文字">
                    <el-input type="textarea" v-model="form.lesson_text" placeholder="本节课时同学们可能用到的文字"></el-input>
                </el-form-item>
                <el-form-item label="教案内容">
                    <el-input type="textarea" v-model="form.lesson_plan"></el-input>
                    <Editor :lessonPlan="form.lesson_plan" :getEditorContent="editEditorContent"></Editor>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加课时" v-model="addVisible" width="70%">
            <el-form ref="form" :model="addData" label-width="70px">
                <el-form-item label="课时名称">
                    <el-input v-model="addData.name"></el-input>
                </el-form-item>
                <el-form-item label="课时封面">
                    <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
                        :show-file-list="false" :on-success="addAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <el-image v-if="addData.image_url" :src="addData.image_url"></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="课时文字">
                    <el-input type="textarea" v-model="addData.lesson_text" placeholder="本节课时同学们可能用到的文字"></el-input>
                </el-form-item>
                <el-form-item label="教案内容">
                    <el-input type="textarea" v-model="addData.lesson_plan"></el-input>
                    <Editor :lessonPlan="addData.lesson_plan" :getEditorContent="addEditorContent"></Editor>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 课时资源  -->
        <el-dialog title="绑定课时资源" v-model="bindToResource" width="30%">
            <el-form ref="form" :model="bindResourceData" label-width="70px">
                <el-form-item label="选择视频">
                    <el-select v-model="bindResourceData.video_id" filterable placeholder="请选择">
                        <el-option label="默认不传" :value="0"></el-option>
                        <el-option v-for="item in videoList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传源码">
                    <!-- <el-upload
                                  class="upload-demo"
                                  action="/resource/v1/uploadFile"
                                  :headers="headersObj"
                                  :on-preview="handlePreview"
                                  :on-remove="handleRemove"
                                  :on-success="uploadCode"
                                  multiple
                                  :limit="3"
                                  :on-exceed="handleExceed"
                                  :file-list="fileList"
                              >
                                  <el-button size="small" type="primary"
                                      >点击上传</el-button
                                  >
                                  <tip></tip>
                                  <div class="el-upload__tip">
                                      只能上传sb3文件
                                  </div>
                              </el-upload> -->
                    <el-upload class="upload-demo" action="/resource/v1/uploadFile" :headers="headersObj"
                        :on-preview="handlePreview" :on-remove="handleRemove" :on-success="uploadCode"
                        :before-remove="beforeRemove" multiple :file-list="fileList" :on-progress="handleProgress"
                        accept=".sb3,.jpg,.sjr">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">只能上传 sb3 ,sjr文件</div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <!--          <el-button @click="bindToResource = false">取 消</el-button>-->
                    <el-button type="primary" @click="saveBindVideoResource">绑定视频</el-button>
                    <el-button type="primary" @click="saveBindCodeResource">绑定代码</el-button>
                </span>
            </template>
            <!-- <GroupPairStudent :bindVisible="bindVisible" :groupId="form.group_id" @cloneStatus="aa"></GroupPairStudent> -->
        </el-dialog>

        <!-- 课时资源  -->
        <el-dialog title="学生课时资源" v-model="bindStudentState" width="30%">
            <el-form ref="form" :model="form2" label-width="80px">
                <el-form-item label="学生代码">
                    <el-upload accept=".sb3,.sjr" class="upload-demo" action="/resource/v1/uploadFile"
                        :headers="headersObj" :on-preview="handlePreview2" :on-remove="handleRemove2"
                        :before-remove="beforeRemove2" :on-exceed="handleExceed2" :file-list="fileList2"
                        :before-upload="beforeUpload2" :on-success="uploadCode2" multiple :limit="1">
                        <el-button size="small" type="primary" v-if="StudentUploadState">点击上传
                        </el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit2">绑定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import Editor from "./editor.vue";
import ConfirmableInput from "./basic-components/ConfirmableInput.vue"

export default {
    name: "basetable",
    data() {
        return {
            flag: true,
            index: -1,
            query: {
                address: "",
                name: "",
                page: 1,
                limit: 9999,
            },
            addData: {
                lesson_text: "",
            },
            bindResourceData: {
                code_id: 0,
            },
            lessonData: [],
            multipleSelection: [],
            delList: [],
            videoList: [],
            editVisible: false,
            addVisible: false,
            bindToResource: false,
            pageTotal: 0,
            form: {
                lesson_text: "",
            },
            form2: {
                edu_lesson_id: 0,
                raw_code_id: 0,
            },
            lesson_group_id: 0,
            idx: -1,
            id: -1,
            imageUrl: "",
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                        ["blockquote", "code-block"], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                        [{ script: "sub" }, { script: "super" }], // 上标/下标
                        [{ indent: "-1" }, { indent: "+1" }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ["small", false, "large", "huge"] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ["clean"], // 清除文本格式
                        ["link", "image", "video"], // 链接、图片、视频
                    ], //工具菜单栏配置
                },
                placeholder: "请在这里添加产品描述", //提示
                readyOnly: false, //是否只读
                theme: "snow", //主题 snow/bubble
                syntax: true, //语法检测
            },
            content: "343342",
            configs: {
                status: true, // 禁用底部状态栏
                initialValue: "Hello BBK", // 设置初始值
                renderingConfig: {
                    codeSyntaxHighlighting: true, // 开启代码高亮
                    highlightingTheme: "atom-one-light", // 自定义代码高亮主题
                },
            },
            fileList: [],
            fileList2: [],
            lessonGroupList: [],
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
            lesson_plan: "",
            codetitle: "",
            planData: [],
            plan_act_id: "",
            plan_lesson_id: 0,
            planState: false,
            bindStudentState: false,
            StudentUploadState: true,
            rec_id: 0,
            raw_code_id: 0,
            studentCodeState: false,
        };
    },
    components: {
        Editor,
        ConfirmableInput,
    },
    props: {
        course_id: { type: Number },
    },
    created() {
        this.getData();
        this.getListAct();
    },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill;
        },
    },
    watch: {},
    methods: {
        onSubmit2() {
            if (this.studentCodeState == false) {
                let params = [this.form2, "/edu/v1.2/bindEduLessonRawCode", "POST"];
                fetchData(...params).then((res) => {
                    console.log(res);
                    this.$message.success("绑定成功");
                    this.planState = true;
                });
            } else {
                // if(this.raw_code_id){}
                let params = [
                    { rec_id: this.rec_id, raw_code_id: this.raw_code_id !== 0 ? this.raw_code_id : -1 },
                    "/edu/v1.2/editEduLessonRawCode",
                    "POST",
                ];
                fetchData(...params).then((res) => {
                    console.log(res);
                    this.$message.success("更改绑定");
                    this.planState = true;
                });
            }

            this.bindStudentState = false;
        },
        uploadCode2(res) {
            console.log(res)
            this.raw_code_id = res.data.id;
            this.form2.raw_code_id = res.data.id;
        },
        beforeUpload2(file) {
            console.log(file);
        },
        handleRemove2(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview2(file) {
            console.log(file);
        },
        handleExceed2(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove2(file, fileList) {
            console.log(fileList);
            this.$confirm(`确定移除 ${file.name}？`, "提示", {
                type: "warning",
            })
                .then(() => {
                    this.raw_code_id = 0;
                    this.StudentUploadState = true;
                })
                .catch(() => {
                });
        },
        bindStudentResource(item) {
            console.log(item);
            this.form2.edu_lesson_id = item.id;
            this.bindStudentState = !this.bindStudentState;
            let params = [
                { edu_lesson_id: item.id },
                "/edu/v1.2/listEduLessonRawCode",
                "POST",
            ];
            fetchData(...params).then((res) => {
                console.log(res);
                var list = [];
                res.data.forEach((element) => {
                    if (element.raw_code_id !== 0) {
                        list.push({
                            id: element.raw_code_id,
                            name: "半截代码",
                            url: element.raw_code_string,
                        });
                    }

                    this.rec_id = element.rec_id;
                    this.raw_code_id = element.raw_code_id;
                });
                this.fileList2 = list;
                console.log(this.fileList2.length);
                if (this.fileList2.length > 0) {
                    this.StudentUploadState = false;
                    this.studentCodeState = true;
                } else {
                    this.studentCodeState = false;
                    this.StudentUploadState = true;
                }
            });
        },
        handleClose() {
            this.getData();
            this.editVisible = false;
        },
        //绑定教案
        bindLessonAct() {
            let lesson_id = this.plan_lesson_id;
            let act_id = this.plan_act_id;
            if (act_id == "") {
                this.$message.error("请选择绑定教案");
                return;
            }
            if (this.planState) {
                this.$message.error("该课时已经绑定过了，请解绑后再绑定");
                return;
            }

            let params = [{ lesson_id, act_id }, "/edu/v1.2/bindLessonAct", "GET"];
            fetchData(...params).then((res) => {
                console.log(res);
                this.$message.success("绑定成功");
                this.planState = true;
            });
        },
        //绑定教案
        unbindLessonAct() {
            let lesson_id = this.plan_lesson_id;
            let act_id = this.plan_act_id;
            if (act_id == "") {
                this.$message.error("请选择绑定教案");
                return;
            }

            let params = [{ lesson_id, act_id }, "/edu/v1.2/unbindLessonAct", "GET"];
            fetchData(...params).then((res) => {
                console.log(res);
                this.$message.success("解绑成功");
                this.plan_act_id = "";
            });
        },
        getListAct() {
            let params = [
                {
                    act_type_id: 7,
                    page: 1,
                    limit: 99999,
                },
                "/act/v1/listActs",
                "GET",
            ];
            fetchData(...params).then((res) => {
                console.log(res);
                this.planData = res.data.list;
            });
        },
        // 获取 easy-mock 的模拟数据
        getData() {
            this.query.course_id = this.course_id;
            fetchData(this.query, "/edu/v1/listLesson", "GET").then((res) => {
                this.lessonData = res.list;
                this.pageTotal = res.count || 0;
            });
            fetchData({ page: 1, limit: 999999 }, "/admin/v1/listVideo", "GET").then(
                (res) => {
                    this.videoList = res.data.list;
                }
            );
            fetchData({}, "/edu/v1.2/listGroups", "GET").then((res) => {
                let groupData = { group_id: 0, group_name: "请选择" };
                this.lessonGroupList = [groupData, ...res.data];
            });
        },
        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            // this.query.name = "";
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData({ id: id }, "/edu/v1/deleteLesson", "GET").then(() => {
                        this.$message.success("删除成功");
                        this.lessonData.splice(index, 1);
                    });
                })
                .catch(() => {
                });
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        lessonAdd(index, row) {
            this.idx = index;
            this.form = row;
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(index, row) {
            console.log(row);
            this.plan_act_id = row.act_id;
            if (row.act_id != 0) {
                this.planState = true;
            } else {
                this.planState = false;
                this.plan_act_id = "";
            }
            this.plan_lesson_id = row.id;
            this.idx = index;
            this.form = row;
            this.lesson_group_id = row.lesson_group_id;
            // this.lesson_plan = row.lesson_plan;
            this.imageId = row.image_id;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            // this.form.lesson_plan = this.lesson_plan;
            this.form.lesson_id = this.form.id;
            this.form.course_id = this.course_id;
            this.form.image_id = this.imageId;
            this.form.lesson_group_id = this.lesson_group_id;
            fetchData(this.form, "/edu/v1/editLesson", "POST").then(() => {
                this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                this.form = {};
                this.editVisible = false;
            });
        },
        // 保存添加
        saveAdd() {
            // this.addData.lesson_plan = this.lesson_plan;
            // console.log(this.addData);
            this.addData.course_id = this.course_id;
            this.addData.image_id = this.imageId;
            fetchData(this.addData, "/edu/v1/createLesson", "POST").then(() => {
                this.$message.success(`添加成功`);
                this.addData = {};
                this.addVisible = false;
                this.getData();
            });
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "pageIndex", val);
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageId = res.data.id;
            this.form.image_url = URL.createObjectURL(file.raw);
        },
        addAvatarSuccess(res, file) {
            this.imageId = res.data.id;
            this.addData.image_url = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            // this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
        // 失去焦点
        onEditorBlur(editor) {
            console.log(editor);
        },
        // 获得焦点
        onEditorFocus(editor) {
            console.log(editor);
        },
        // 开始
        onEditorReady(editor) {
            console.log(editor);
        },
        // 值发生变化
        onEditorChange(editor) {
            this.content = editor.html;
            console.log(editor);
        },
        bindResource(val) {
            this.bindToResource = true;
            this.bindResourceData.lesson_id = val.id;
            this.bindResourceData.video_id = val.video_id;
            this.getFileList();
        },
        saveBindVideoResource() {
            if (this.bindResourceData.video_id != 0) {
                fetchData(
                    this.bindResourceData,
                    "/admin/v1/videoBindingToLesson",
                    "POST"
                ).then(() => {
                    this.$message.success(`绑定成功`);
                    // this.bindToResource = false;
                    this.getData();
                });
            }
        },
        saveBindCodeResource() {
            // this.bindResourceData.course_id = this.course_id;
            // this.bindResourceData.image_id = this.imageId;
            fetchData(
                {
                    lesson_id: this.bindResourceData.lesson_id,
                    code_id: this.bindResourceData.code_id,
                    code_title: this.codetitle,
                    code_context: "",
                },
                "/edu/v1.2/bindExtraLessonCode",
                "GET"
            ).then(() => {
                var liList = document.getElementsByClassName(
                    "el-upload-list__item-name"
                );
                liList[liList.length - 1].innerHTML =
                    '<i class="el-icon-document"></i>' + this.codetitle;

                this.$message.success(`绑定成功`);
                // this.bindToResource = false;
                this.getData();
            });
        },
        addEditorContent(val) {
            // console.log(val);
            this.addData.lesson_plan = val;
        },
        editEditorContent(val) {
            // console.log(val);
            this.form.lesson_plan = val;
        },

        //上传源码
        // 源码列表
        getFileList() {
            fetchData(
                { lesson_id: this.bindResourceData.lesson_id },
                "/edu/v1.2/listExtraLessonCode",
                "GET"
            ).then((res) => {
                var list = [];
                res.data.forEach((element) => {
                    list.push({
                        id: element.id,
                        name: element.code_title,
                        url: element.code_url,
                    });
                    this.bindResourceData.code_id = element.id;
                });
                this.fileList = list;
            });
        },
        beforeRemove(file) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 删除源码
        handleRemove(file, fileList) {
            fetchData(
                { extra_code_id: file.id },
                "/edu/v1.2/deleteExtraLessonCode",
                "GET"
            ).then(() => {
                this.fileList = fileList;
            });
        },
        //文件上传时
        handleProgress(event, file) {
            var fileNameArr = file.name.split("."),
                name = prompt("请输入代码名称", fileNameArr[0]);
            this.codetitle = name;
        },
        // 点击文件列表中已上传的文件时的钩子
        // handlePreview(file) {
        // alert(2342);
        // console.log(file);
        // },
        // 当超过上传限制时
        // handleExceed(files, fileList) {
        //     this.$message.warning(
        //         `当前限制选择 3 个文件，本次选择了 ${
        //             files.length
        //         } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        //     );
        // },
        uploadCode(res) {
            // var urlArr = res.data.url.split('/');
            if (this.codetitle === null) {
                return false;
            }

            this.bindResourceData.code_id = res.data.id;
        },

        // 失去焦点修改课时排序
        blurPos(res) {
            fetchData(
                {
                    id: res.id,
                    lesson_group_id: res.lesson_group_id,
                    pos: res.pos,
                },
                "/edu/v1/editLesson",
                "POST"
            ).then(() => {
                this.$message.success(`修改成功`);
                this.form = {};
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: auto !important;
    height: auto !important;
    border: none;
}

.upload-demo .el-upload {
    width: 100% !important;
    border: none !important;
}
</style>
