<template>
  <transition-group
    class="drag-sort-box"
    name="sort"
    tag="div"
    @dragover="dragover($event)"
    style="display: flex; gap: 20px"
  >
    <div
      v-for="(item, index) in images"
      :key="index"
      style="border: 1px solid #666666; padding: 8px; border-radius: 10px"
    >
      <div style="display: flex; align-items: center">
        动作名：<el-input v-model="item.intro" style="width: 100px"></el-input>
      </div>
      <div style="display: flex; align-items: center">
        角色名：<el-input v-model="item.title" style="width: 100px"></el-input>
      </div>
      <div
        :class="[itemClass, 'drag-sort-item']"
        :draggable="true"
        @dragstart="dragstart(item, index)"
        @dragenter="dragenter(item, $event)"
        @dragend="dragend(item, $event)"
        @dragover="dragover($event)"
      >
        <!-- <div
        v-if="item.intro !== ''"
        class="intro"
        @click="handleEdit(index, item)"
      >
        {{ item.intro }}
      </div>
      <i v-else class="el-icon-edit" @click="handleEdit(index, item)"></i> -->

        <i class="el-icon-error" @click="handleDeleteImg(index, item)"></i>
        <p class="moxsind">{{ index + 1 }}</p>

        <!-- <img
          :src="item"
          :class="index == dargIndex ? 'active' : ''"
          @click="handleBigImg(item)"
        /> -->
        <el-image
          :class="index == dargIndex ? 'active' : ''"
          :src="item.img"
          :preview-src-list="srcList"
          @click="handleBigImg(item.img)"
        >
        </el-image>
        <!-- <div style="">
          <img
            style="height: 100%"
            :src="item.img"
            alt=""
            :preview-src-list="srcList"
          />
        </div> -->
      </div>
    </div>

    <!-- <div class="img-big-class">
        <img :src="imgUrl" alt="" />
      </div> -->
  </transition-group>
</template>

  <script>
import { ref } from "vue";
export default {
  name: "DragSort",
  props: {
    imageList: Array, // 传入图片数据
    introArr: Array, // 传入图片数据
    sortType: {
      // 排序方式
      type: String,
      default: "insert",
    },
    itemClass: String, //自定义类名
    title: String,
  },
  data() {
    return {
      imgUrl: "",
      srcList: [],
      introList: [],
    };
  },
  computed: {},
  watch: {
    imageList: {
      handler: function (newVal) {
        console.log(newVal.isTrusted);
        // if(typeof newVal){}
        if (newVal.isTrusted !== true) {
          this.images = newVal;
        }
      },
      immediate: true, //初始化页面后立即监听
    },
    introArr(newVal) {
      console.log(newVal);
      this.introList = newVal;
    },
  },
  setup(prop, ctx) {
    let images = ref([...prop.imageList]);
    console.log(images);
    let dargIndex = ref(-1);

    let oldData = null;
    let newData = null;

    function dragstart(value, index) {
      oldData = value;
      dargIndex.value = index;
    }
    function dragenter(value, e) {
      newData = value;
      e.preventDefault();
    }

    function dragover(e) {
      e.preventDefault();
    }

    function dragend() {
      if (oldData !== newData) {
        let oldIndex = images.value.indexOf(oldData);
        let newIndex = images.value.indexOf(newData);

        if (prop.sortType == "insert") {
          let newItems = [...images.value];
          newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, oldData);
          images.value = [...newItems];
        } else {
          [images.value[oldIndex], images.value[newIndex]] = [
            images.value[newIndex],
            images.value[oldIndex],
          ];
        }
        console.log(images.value);
        ctx.emit("change", images.value);
      }
      dargIndex.value = -1;
    }

    return {
      images,
      dargIndex,

      dragover,
      dragstart,
      dragenter,
      dragend,
    };
  },
  methods: {
    handleDeleteImg(index, item) {
      this.images.splice(index, 1);
      this.$emit("delIntroduceItem", { index, url: item });
    },
    handleBigImg(url) {
      //   this.imgUrl = url;
      console.log(url);
      this.srcList[0] = url;
    },
    handleEdit(index, item) {
      console.log(index);
      item.openeidt = true;
    },
    handleEnter(index, item) {
      console.log(item);
      item.openeidt = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1;
}
.drag-sort-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.drag-sort-box .drag-sort-item {
  width: 160px;
  height: 200px;
  margin: 2px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  background: #ccc;
  position: relative;
  display: flex;
  justify-content: center;
}

.drag-sort-box .drag-sort-item img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  position: relative;
}
.drag-sort-box .drag-sort-item .active {
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.moxsind {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dfd6d6;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 50px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}

.el-icon-edit {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  color: red;
  font-size: 26px;
}
.el-icon-error {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  color: red;
  font-size: 26px;
}
.img-big-class {
  position: fixed;
  top: 20%;
  left: 20%;
  width: 60%;
  margin: 0 auto;
}

::v-deep .el-image-viewer__canvas img {
  width: auto !important;
}

::v-deep .el-image__inner {
  /* width: 180% !important; */
  /* margin-left: -6rem; */
}

.intro {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  color: red;
  font-size: 20px;
  font-weight: bold;
}
::v-deep .el-image {
  height: 100%;
}
::v-deep .el-image__inner {
  height: 100%;
}
</style>

