<template>
  <div>
    <el-button type="primary" @click="handleAdd">添加图片</el-button>
    <el-table
      :data="tableData"
      :border="true"
      style="width: 100%"
      :style="{ 'margin-top': '16px' }"
    >
      <el-table-column prop="img_amount" label="简介名称"> </el-table-column>
      <el-table-column prop="name" label="图片"> </el-table-column>
      <el-table-column prop="intro" label="排序"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button
            size="mini"
            @click="handleRemove(scope.row.id)"
            type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="page"
        :page-size="limit"
        :total="total"
        @current-change="handlePageChange"
      >
      </el-pagination>

      <div class="form-box">
        <el-dialog
          :title="title"
          v-model="formState"
          width="50%"
          :before-close="handleClose"
        >
          <div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="图片上传" prop="name">
                <el-upload
                  class="avatar-uploader"
                  action="/resource/v1/uploadFile"
                  :headers="headersObj"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="图片排序" prop="img_amount">
                <el-input
                  v-model="ruleForm.img_amount"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="图片简介" prop="intro">
                <el-input type="textarea" v-model="ruleForm.intro"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >立即创建</el-button
                >
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

  <script>
import { fetchData } from "@/api/api";
export default {
  data() {
    return {
      title: "添加图片",
      tableData: [],
      total: 0,
      limit: 10,
      page: 1,
      formState: true,
      ruleForm: {
        name: "",
        img_amount: 0,
        intro: "",
      },
      rules: {
        name: [{ required: true, message: "请输入图片名称", trigger: "blur" }],
        img_amount: [
          { required: true, message: "请输入图片排序", trigger: "blur" },
        ],
        intro: [{ required: true, message: "请输入图片简介", trigger: "blur" }],
      },
      id: 0,

      imageUrl: "",
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    getList() {
      const params = [
        { page: this.page, limit: this.limit },
        "/edu/v1/listEduCartoonGroup",
        "GET",
      ];
      fetchData(...params).then((res) => {
        this.total = res.data.count;
        this.tableData = res.data.list;
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleAdd() {
      this.id = 0;
      this.title = "创建图片图片";
      this.formState = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.ruleForm = {
        name: item.name,
        img_amount: item.img_amount,
        intro: item.intro,
      };
      this.title = "编辑图片图片";
      this.formState = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title === "创建图片图片") {
            const params = [
              { ...this.ruleForm },
              "/edu/v1/createEduCartoonGroup",
              "POST",
            ];
            fetchData(...params).then(() => {
              this.$message.success("新增成功");
              this.getList();
              this.formState = false;
            });
          } else {
            const params = [
              { ...this.ruleForm, group_id: this.id },
              "/edu/v1/editEduCartoonGroup",
              "POST",
            ];
            fetchData(...params).then(() => {
              this.$message.success("编辑成功");
              this.getList();
              this.formState = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**删除 */
    handleRemove(id) {
      this.$alert("删除图片请慎重", "删除图片", {
        confirmButtonText: "确定",
        callback: () => {
          const params = [
            { group_id: id },
            "/edu/v1/deleteEduCartoonGroup",
            "POST",
          ];
          fetchData(...params).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        },
      });
    },
    /**重置 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**查看动作图片 */
    handleCheck(item) {
      console.log(item);
      this.$emit("handleCheck", item);
    },

    handleAvatarSuccess(data, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(data);
      data.data.id;
      // this.actBodyObj.body_image_url = data.data.relativeUrl;
      //   this.actBodyObj.body_image_id = data.data.id;
      //   this.addImgItem(data.data.relativeUrl);
      //   console.log(this.actBodyList.length);
      //   this.loadingAlert.close();
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.pagination {
  text-align: start;
}

::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
}
::v-deep.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
