<template>
  <div class="main">
    <div style="display: flex; align-items: center">
      <div>选择图片组别：</div>
      <el-select
        v-model="group_id"
        placeholder="请选择"
        clearable
        @change="handleUnBind"
      >
        <el-option
          v-for="item in groupData"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          :disabled="disabled"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="groupData.length !== 0 && groupItem.length !== 0">
      <div
        v-if="groupItem.edu_cartoon_images.length !== 0"
        style="display: flex; flex-wrap: wrap"
      >
        <div
          style="
            margin-right: 16px;
            margin-top: 16px;
            border: 1px solid #999999;
            border-radius: 10px;
            width: 160px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          "
          v-for="(item, index1) in groupItem.edu_cartoon_images"
          :key="index1"
        >
          <div v-if="item.intro !== ''">动作名：{{ item.intro }}</div>
          <div v-else>动作名：<span style="color: red;">未填写</span></div>

          <div v-if="item.intro !== ''">角色名：{{ item.intro }}</div>
          <div v-else>角色名：<span style="color: red;">未填写</span></div>
          <!-- <div style="font-size: 18px; font-weight: bold; text-align: center">
              {{ item.intro }}
            </div> -->
          <!-- <img :src="item.real_image_url" alt="" style="height: 100%" /> -->
          <el-image :src="item.real_image_url"> </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "../../../api/api";

export default {
  name: "AdminLabel",
  components: {},
  computed: {},
  props: ["courseItem", "cgState"],
  emits: [],
  data() {
    return {
      // category: 'bureau',
      // categoryId: 1,
      moreTagsOpen: false,
      groupData: [],
      group_id: null,
      disabled: false,
      del_group_id: null,
      groupItem: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    cgState(val) {
      if (val === true) {
        this.group_id = null;

        this.getList();

        console.log(this.courseItem);
        this.moreTagsOpen = val;
      }
    },
  },
  methods: {
    getList() {
      const params = [
        { page: 1, limit: 999999 },
        "/edu/v1/listEduCartoonGroup",
        "GET",
      ];
      fetchData(...params).then((res) => {
        if (this.courseItem.edu_cartoon_group_id !== 0) {
          this.group_id = this.courseItem.edu_cartoon_group_id;
          this.disabled = true;
          this.del_group_id = this.courseItem.edu_cartoon_group_id;

          const groupItem = res.data.list.find(
            (v) => v.id === this.courseItem.edu_cartoon_group_id
          );
          this.groupItem = groupItem;
        } else {
          this.group_id = null;
          this.disabled = false;
          this.del_group_id = null;
          this.groupItem = [];
        }
        this.groupData = res.data.list;
      });
    },
    /**解绑 */
    handleUnBind(val) {
      if (val !== "") {
        this.del_group_id = val;
        const params = [
          { group_id: val, course_id: this.courseItem.id },
          "/edu/v1/bindEduCartoonGroupCourse",
          "post",
        ];
        fetchData(...params).then(() => {
          this.$message.success("绑定成功");
          this.$emit("refreshData");
          this.disabled = true;
          const groupItem = this.groupData.find((v) => v.id === val);
          this.groupItem = groupItem;
          //   this.groupData = res.data.list;
        });
      } else {
        const params = [
          { group_id: this.del_group_id, course_id: this.courseItem.id },
          "/edu/v1/unbindEduCartoonGroupCourse",
          "post",
        ];
        fetchData(...params).then(() => {
          this.$emit("refreshData");
          this.disabled = false;
          this.groupItem = [];
          this.$message.success("解绑成功");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  text-align: left;
}
</style>
