<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 代理列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="openVisible('add')">添加代理</el-button>
            </div>
            <el-table :data="agencyList" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column prop="agency.agency_id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="agency.agency_name" label="代理机构名称"></el-table-column>
                <el-table-column prop="agency.agency_address" label="代理机构地址"></el-table-column>
                <el-table-column label="用户">
                    <template #default="scope">
                        <div v-if="scope.row.user.user_phone">
                            <div>负责人姓名：{{ scope.row.user.user_nickname }}</div>
                            <div>负责人手机号：{{ scope.row.user.user_phone }}</div>
                            <div>
                                <el-button type="text" @click="openVisibleOfAgencyUser('edit', scope.row.user)">编辑
                                </el-button>
                                <el-button type="text" @click="agencyUserDelete(scope.row.user.user_id)"
                                    style="color: red;">删除</el-button>
                            </div>
                        </div>
                        <div v-else>
                            <el-button icon="el-icon-plus" type="primary"
                                @click="openVisibleOfAgencyUser('add', { agency_id: scope.row.agency.agency_id, is_admin: true })">
                                创建代理人
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click="openVisible('edit', scope.row.agency)">编辑
                        </el-button>
                        <el-button type="text" icon="el-icon-edit" @click="goodsBindingBureau(scope.row.agency)">代理绑定机构
                        </el-button>
                        <el-button type="text" icon="el-icon-delete" class="red"
                            @click="handleDelete(scope.$index, scope.row.agency.agency_id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
        <!-- 弹出框 -->
        <el-dialog :title="dialog.title" v-model="dialog.isOpen" width="30%">
            <el-form ref="form" :model="dialog.data" label-width="120px">
                <el-form-item :label="item.lable" v-for="(item, index) in dialog.field" :key="index">
                    <el-input v-if="item.itemType == 'input'" v-model="dialog.data[item.field]"></el-input>
                    <el-switch v-if="item.itemType == 'switch'" v-model="dialog.data[item.field]" active-color="#13ce66"
                        inactive-color="#ff4949"></el-switch>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveFun()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog title="绑定机构" v-model="bindVisible" width="30%">
            <el-form ref="form" :model="bindData" label-width="70px">
                <el-form-item label="选择机构">
                    <el-select v-model="bindData.bureau_id" filterable multiple placeholder="请选择">
                        <el-option v-for="item in bureauData" :key="item.id" :label="item.name" :value="item.id" :disabled="isChecked(item.id)">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveBind">确 定</el-button>
                </span>
            </template>
            <!-- <GroupPairStudent :bindVisible="bindVisible" :groupId="form.group_id" @cloneStatus="aa"></GroupPairStudent> -->
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
export default {
    name: "goods",
    data() {
        return {
            query: {
                address: "",
                name: "",
                page: 1,
                limit: 10,
            },
            addData: {
                price: 0,
                hours: 0
            },
            bindData: {},
            bureauData: [],
            multipleSelection: [],
            delList: [],
            visible: false,
            editVisible: false,
            addVisible: false,
            bindVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            imageUrl: "",

            visibleType: "",
            agencyData: {},


            agencyList: [],
            dialog: {
                field: [
                    {
                        itemType: 'input',
                        field: 'agency_name',
                        lable: '代理机构名',
                    }
                ],
                data: {

                },
                isOpen: false,
                title: "",
                fun: () => { }
            },
            dialogUrl: "",
            dialogText: "",
            selectBureau: []
        };
    },
    components: {
    },
    computed: {
    },
    created() {
        this.getData();
        fetchData({ page: 1, limit: 999999 }, "/bureau/v1/listBureau", "GET").then((res) => {
            this.bureauData = res.data.list;
        });
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(this.query, "/agency/v1/listAgency", "GET").then((res) => {
                this.agencyList = res.data.list;
                this.pageTotal = res.data.count || 0;
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.query.name = "";
            this.getData();
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },

        initagencyData(data) {
            this.agencyData = {
                id: data ? data.id : '',
                days: data ? data.days : '',
                member_count: data ? data.member_count : 20000,
                price: data ? data.price : '',
                name: data ? data.name : '',
                hours: data ? data.hours : 10,
                goods_category: data ? data.goods_category : 'by_memeber_count',
                target_user: data ? data.target_user : 'all',
                student_account_limit: data ? data.student_account_limit : 0,
                teacher_account_limit: data ? data.teacher_account_limit : 0,
                lesson_duration: data ? data.lesson_duration : '',
                week_limit: data ? data.week_limit : '',
                disable_student_account: data ? data.disable_student_account : false
            };
        },


        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { agency_id: id },
                        "/agency/v1/deleteAgency",
                        "POST"
                    ).then(() => {
                        this.$message.success("删除成功");
                        this.agencyList.splice(index, 1);
                    });
                })
                .catch(() => { });
        },
        // 删除负责人
        agencyUserDelete(id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { user_id: id },
                        "/agency/v1/deleteAgencyUser ",
                        "POST"
                    ).then(() => {
                        this.$message.success("删除成功");
                        this.getData();
                    });
                })
                .catch(() => { });
        },
        // 打开添加或编辑弹框添加代理机构
        openVisible(type, data = {}) {
            this.dialog.isOpen = true;
            this.dialog.title = '添加代理机构';
            this.dialog.field = [
                {
                    itemType: 'input',
                    field: 'agency_name',
                    lable: '代理机构名',
                }, {
                    itemType: 'input',
                    field: 'agency_address',
                    lable: '代理机构地址',
                }
            ];
            if (type == 'add') {
                this.dialogUrl = '/agency/v1/createAgency';
                this.dialogText = '添加成功';
            }
            if (type == 'edit') {
                this.dialogUrl = '/agency/v1/updateAgency';
                this.dialogText = '修改成功';
            }
            this.dialog.data = data;
        },
        // 打开添加或编辑弹框添加代理机构负责人
        openVisibleOfAgencyUser(type, data = {}) {
            this.dialog.isOpen = true;
            this.dialog.title = '添加代理机构负责人';
            this.dialog.field = [
                {
                    itemType: 'input',
                    field: 'user_nickname',
                    lable: '代理人昵称',
                }, {
                    itemType: 'input',
                    field: 'user_phone',
                    lable: '代理人手机号',
                }
            ];
            if (type == 'add') {
                this.dialogUrl = '/agency/v1/createAgencyUser';
                this.dialogText = '添加成功';
            }
            if (type == 'edit') {
                this.dialogUrl = '/agency/v1/updateAgencyUser';
                this.dialogText = '修改成功';
            }
            this.dialog.data = data;
        },
        // 保存操作
        saveFun() {
            fetchData(this.dialog.data, this.dialogUrl, "POST").then(() => {
                this.getData();
                this.$message.success(this.dialogText);
                this.dialog.isOpen = false;
            });
        },

        goodsBindingBureau(row) {
            fetchData(
                { agency_id: row.agency_id },
                "/agency/v1/listAgencyBureau",
                "POST"
            ).then((res) => {
                this.bindVisible = true
                this.bindData.agency_id = row.agency_id;
                this.bindData.course_id = row.course_id;
                this.bindData.bureau_id = [];
                res.data.forEach(item => {
                    this.selectBureau.push(item.bureau_id);
                });
            });
        },
        isChecked(id) {
            return this.selectBureau.includes(id);
        },
        // 提交绑定
        saveBind() {
            let param = {
                agency_id: this.bindData.agency_id,
                bureau_id: this.bindData.bureau_id.toString()
            }
            fetchData(
                param,
                "/agency/v1/bindAgencyBureau",
                "POST"
            ).then(() => {
                this.$message.success(`绑定成功`);
                this.bindVisible = false;
            });
        },



        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isLt2M;
        },

    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>
