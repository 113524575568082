<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 视频列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="VideoAdd"
                    >添加视频</el-button
                >
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    class="right"
                    @click="handleSearch"
                    >搜索</el-button
                >
                <el-input
                    v-model="query.name"
                    placeholder="视频名"
                    class="handle-input mr10 right"
                ></el-input>
            </div>
            <el-table
                :data="VideoData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="id"
                    label="ID"
                    width="55"
                    align="center"
                ></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <!-- <el-table-column prop="video_duration" label="视频时长"></el-table-column> -->
                <el-table-column label="视频封面">
                    <template #default="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.image_url"
                            :preview-src-list="[scope.row.image_url]"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-popover
                            placement="top-start"
                            :width="240"
                            trigger="click"
                        >
                            <template #reference>
                                <el-button
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="thePointTimeIndexId = 0"
                                    >暂停时间点
                                </el-button>
                            </template>
                            <p v-if="scope.row.point_in_time.length === 0" style="color:#999;text-align:center;">暂无时间点</p>
                            <span
                                v-for="timeItem in scope.row.point_in_time" 
                                :key="timeItem" 
                                class="pointTime"
                                :class="thePointTimeIndexId === timeItem.id ? 'pointPitch' : ''"
                                @click="thePointTimeIndexId = timeItem.id, thePointTimeIndexTime = timeItem.time"
                                >{{ secondToMinute(timeItem.time).str }}</span>
                            <p style="text-align:right; margin-top: 1rem;">
                                <el-button-group>
                                    <el-button type="success" size="mini" @click="addPointTime(scope.row.id)">添加</el-button>
                                    <el-button type="primary" size="mini" @click="editPointTime">修改</el-button>
                                    <el-button type="danger" size="mini" @click="delPointTime">删除</el-button>
                                </el-button-group>
                            </p>
                        </el-popover>
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            style="color:red;"
                            @click="handleDelete(scope.$index, scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改视频" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="视频名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="视频时长">
                    <el-input v-model="form.video_duration"></el-input>
                </el-form-item> -->
                <el-form-item label="视频封面">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <el-image
                            v-if="form.image_url"
                            :src="form.image_url"
                        ></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="视频路径">
                    <el-input v-model="form.former_url"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加视频" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="70px">
                <el-form-item label="视频名">
                    <el-input v-model="addData.video_name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="视频时长">
                    <el-input v-model="addData.video_duration"></el-input>
                </el-form-item> -->
                <el-form-item label="视频封面">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="addAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <el-image
                            v-if="addData.image_url"
                            :src="addData.image_url"
                        ></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="视频路径">
                    <el-input v-model="addData.video_url"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import { h } from "vue";
export default {
    name: "basetable",
    data() {
        return {
            query: {
                address: "",
                name: "",
                page: 1,
                limit: 10,
            },
            addData: {},
            VideoData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            addVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            imageUrl: "",
            headersObj: {token: JSON.parse(localStorage.getItem('adminInfo')).token},
            thePointTimeIndexId: 0,
            thePointTimeMinute: 0,
            thePointTimeSecond: 0,
            thePointTimeIndexTime: 0,
            addPointTimeHtml: h('p', null, [
                    h('input', {id: "inputMinuteData", type:"text", style:'width: 130px; height: 30px; border: 1px solid #ddd; border-radius: 5px; padding: 0px 13px;' , placeholder:"请输入分钟数"}, ''),
                    h('span', { style: 'margin: 0 5px' }, ':'),
                    h('input', {id: "inputSecondData", type:"text", style:'width: 130px; height: 30px; border: 1px solid #ddd; border-radius: 5px; padding: 0px 13px;' , placeholder:"请输入秒数"}, ''),
                ]),
            addPointTimeHtmlArr: []
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(this.query, "/admin/v1/listVideo", "GET").then((res) => {
                console.log(res);
                this.VideoData = res.data.list;
                this.pageTotal = res.data.count || 0;
            });
        },
        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            this.query.name = "";
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { video_id: id },
                        "/admin/v1/deleteVideo",
                        "GET"
                    ).then((res) => {
                        console.log(res);
                        this.$message.success("删除成功");
                        this.VideoData.splice(index, 1);
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        VideoAdd() {
            this.addData = {};
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            this.form.video_url = this.form.former_url;
            this.form.video_id = this.form.id;
            this.form.video_name = this.form.name;
            this.form.video_duration = this.form.duration;
            fetchData(this.form, "/admin/v1/editVideo", "POST").then(
                (res) => {
                    console.log(res);
                    this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                    this.editVisible = false;
                    this.getData();
                }
            );
        },
        // 保存添加
        saveAdd() {
            this.addData.video_duration = 10;
            fetchData(this.addData, "/admin/v1/createVideo", "POST").then(
                (res) => {
                    console.log(res);
                    this.$message.success(`添加成功`);
                    this.addVisible = false;
                    this.getData();
                }
            );
        },
        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.form.image_id = res.data.id;
            this.form.image_url = URL.createObjectURL(file.raw);
        },
        addAvatarSuccess(res, file) {
            this.addData.image_id = res.data.id;
            this.addData.image_url = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
                // this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isLt2M;
        },
        secondToMinute(secondNum) {
            var data = {str:'', sec:0, min: 0};
            data.sec = secondNum % 60;
            data.min = Math.floor(secondNum / 60);
            data.str = data.min + '分' + data.sec + '秒';
            return data;
        },
        addPointTime(videoid) {
            var pointArr = [this.addPointTimeHtml];
            this.$msgbox({
                title: '添加时间点',
                message: h('div',null, [h('span', null, ''),h('div',null, pointArr)]),
                showCancelButton: true,
                dangerouslyUseHTMLString:true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                fetchData({minute: document.getElementById('inputMinuteData').value, second: document.getElementById('inputSecondData').value, video_id: videoid}, "/admin/v1/addVideoPointInTime", "POST").then(
                    () => {
                        this.$message.success(`添加成功`);
                        this.getData();
                    }
                );
            });
        },
        editPointTime() {
            if (this.thePointTimeIndexId === 0) {
                this.$message.error(`您未选中`);
                return false;
            }
            var data = this.secondToMinute(this.thePointTimeIndexTime);
            console.log(data);
            this.$msgbox({
                title: '修改时间',
                message: h('p', null, [
                    h('input', {id: "inputMinuteData", type:"text", style:'width: 130px; height: 30px; border: 1px solid #ddd; border-radius: 5px; padding: 0px 13px;' , placeholder:"请输入分钟数", value: data.min}, ''),
                    h('span', { style: 'margin: 0 5px' }, ':'),
                    h('input', {id: "inputSecondData", type:"text", style:'width: 130px; height: 30px; border: 1px solid #ddd; border-radius: 5px; padding: 0px 13px;' , placeholder:"请输入秒数", value: data.sec}, ''),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                fetchData({minute: document.getElementById('inputMinuteData').value, second: document.getElementById('inputSecondData').value, id: this.thePointTimeIndexId}, "/admin/v1/editVideoPointInTime", "POST").then(
                    (res) => {
                        console.log(res);
                        this.thePointTimeMinute = this.thePointTimeSecond = 0;
                        this.$message.success(`修改成功`);
                        this.getData();
                    }
                );
            })
        },
        delPointTime() {
            if (this.thePointTimeIndexId === 0) {
                this.$message.error(`您未选中`);
                return false;
            }
            if (confirm('确认删除吗？') === true) {
                fetchData({id: this.thePointTimeIndexId}, "/admin/v1/delVideoPointInTime", "GET").then(
                    () => {
                        this.$message.success(`删除成功`);
                        this.getData();
                    }
                );
            } 

        }
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}
.pointTime {
    display: inline-block;
    font-size: 12px;
    border: 1px solid #ddd;
    padding: 4px 10px;
    border-radius: 5px;
    margin: 2px 3px;
    width: 52px;
}
.pointPitch{
    color: #fff;
    background: #409EFF;
}
.minuteInput {
    width: 130px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0px 13px;
}
</style>
