<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 机构幼儿园统计
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div class="count-box">
          <div>
            所有机构限制人数：<span>{{ allBureauPeopleData.bureau_limit }}</span
            >人
          </div>
          <div style="margin-left: 16px">
            所有机构使用人数：<span>{{
              allBureauPeopleData.bureau_amount
            }}</span
            >人
          </div>
        </div>
        <div>
          <el-checkbox v-model="checked" @change="handleCheckbox"
            >已到期机构</el-checkbox
          >
        </div>
        <div class="handle-box">
          <el-select
            v-model="selectTagId"
            placeholder="选择标签搜索"
            class="right"
            @change="handleSearch"
          >
            <el-option
              v-for="item in bureauTags"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="right"
            style="margin-right: 16px"
            @click="handleInputSearch"
            >搜索</el-button
          >
          <el-input
            v-model="selectName"
            placeholder="请输入机构名称"
            class="handle-input mr10 right"
            @keydown="handleInputSearch"
          ></el-input>
        </div>
      </div>

      <el-table
        :data="list"
        :border="true"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          prop="bureau_id"
          label="ID"
          width="55"
          :align="center"
        ></el-table-column>
        <el-table-column label="LOGO" :align="center">
          <template #default="scope">
            <el-avatar
              shape="square"
              :size="40"
              :src="scope.row.bureau_avatar"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="bureau_name" label="机构名称"></el-table-column>
        <el-table-column prop="bureau_limit" label="限制人数">
          <template #default="scope">
            <div>{{ scope.row.bureau_limit }}人</div>
          </template>
        </el-table-column>
        <el-table-column label="已经使用人数">
          <template #default="scope">
            <div>{{ scope.row.bureau_amount }}人</div>
          </template>
        </el-table-column>
        <el-table-column prop="expire_time" label="到期时间"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "../../api/api";
export default {
  name: "helper",
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
      },
      addData: {},
      list: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      tag_list: 0,
      selectName: "",

      bureauTags: [],
      selectTagId: null,

      allBureauPeopleData: {},
      checked: false,
      only_valid: 1,
    };
  },
  created() {
    this.getData();
    this.getTotalPeople();
    this.getBureauTags();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      let prams = this.query;
      if (this.tag_list !== 0 && this.tag_list !== null) {
        prams.tag_list = this.tag_list;
      }
      prams.only_valid = this.only_valid;

      if (this.selectName !== "") {
        prams.name = this.selectName;
      } else {
        prams.name = "";
      }
      console.log(this.selectName);
      fetchData(prams, "/admin/v1/listBureauAccountListInfo", "GET").then(
        (res) => {
          this.list = res.data.list;
          this.pageTotal = res.data.count || 0;
        }
      );
    },
    getTotalPeople() {
      let prams = {};
      if (this.tag_list !== 0 && this.tag_list !== null) {
        prams.tag_list = this.tag_list;
      }
      prams.only_valid = this.only_valid;

      fetchData(prams, "/admin/v1/listTotalBureauAccountListInfo", "GET").then(
        (res) => {
          this.allBureauPeopleData = res;
        }
      );
    },
    getBureauTags() {
      fetchData(
        { page: 1, limit: 999999, category: "bureau" },
        "/bureau/v1.2/listTags",
        "GET"
      ).then((res) => {
        this.bureauTags = res.data.list;
      });
    },
    // 触发搜索按钮
    handleSearch(val) {
      // this.$set(this.query, "pageIndex", 1);
      this.tag_list = val;
      this.query.page = 1;
      this.getData();
      this.getTotalPeople();
    },
    handleInputSearch() {
      console.log(this.selectName);
      this.query.page = 1;
      this.getData();
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getData();
    },
    handleCheckbox(state) {
      if (state) {
        this.only_valid = 0;
      } else {
        this.only_valid = 1;
      }
      this.query.page = 1;
      this.getData();
      this.getTotalPeople();
    },
  },
};
</script>

<style lang="scss" scoped>
.handle-box {
  /* margin-bottom: 20px; */
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.left {
  float: left;
}
.right {
  float: right;
}
.table-td-avatar-url {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.form-avatar-url {
  display: block;
  width: 40px;
  height: 40px;
}

.count-box {
  display: flex;
  div {
    background: #f0f0f0;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    color: #666666;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
