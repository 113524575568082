<template>
  <div>
    <div>
      <el-dialog
        title="查询解锁学生数量"
        v-model="setAlertState"
        width="40%"
        :before-close="handleClose"
      >
        <div>
          <div>
            开始时间：
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择开始日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="handleTimeChange"
            >
            </el-date-picker>
          </div>
          <div style="margin-top: 12px" v-if="startData !== ''">
            <h3>开始时间从 {{ startData }} 生成的记录</h3>
            <div style="margin-top: 8px">
              总解锁的人数：{{ countData.total_unlock_account_num }}人
            </div>
            <div style="margin-top: 8px">
              有上课记录人数：{{ countData.total_valid_account_num }}人
            </div>
            <div style="margin-top: 8px">
              无上课记录人数：{{ countData.total_invalid_account_num }}人
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DataUtils from "../../../utils/dateUtils";
import { fetchData } from "../../../api/api";
export default {
  props: {
    bureauId: {},
  },
  data() {
    return {
      setAlertState: false,
      startTime: "",
      startData: "",
      countData: {},
    };
  },
  methods: {
    handleTimeChange(time) {
      console.log(time);
      if (time !== null) {
        let startData = DataUtils.formatDate(
          new Date(time).getTime() / 1000,
          "YY-MM-DD"
        );
        this.startData = startData;
        console.log(startData);
        const params = [
          {
            start_time: startData,
            bureau_id: this.bureauId,
          },
          "/admin/v1/listBureauLessonFeeInfo",
          "POST",
        ];
        fetchData(...params).then((res) => {
          console.log(res);
          this.countData = res.data;
        });
      } else {
        this.startData = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
