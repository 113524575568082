<template>
  <div>
    <el-button type="primary" @click="handleAdd">添加图片组</el-button>
    <el-table
      :data="tableData"
      :border="true"
      style="width: 100%"
      :style="{ 'margin-top': '16px' }"
    >
      <el-table-column prop="name" label="图片组名称"> </el-table-column>
      <el-table-column prop="intro" label="简介"> </el-table-column>
      <el-table-column prop="img_amount" label="示例图数量（张）"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button
            size="mini"
            @click="handleRemove(scope.row.id)"
            type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="page"
        :page-size="limit"
        :total="total"
        @current-change="handlePageChange"
      >
      </el-pagination>

      <div class="form-box">
        <el-dialog
          :title="title"
          v-model="formState"
          width="60%"
          :before-close="handleClose"
        >
          <div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="组别名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>

              <el-form-item label="组别简介" prop="intro">
                <el-input type="textarea" v-model="ruleForm.intro"></el-input>
              </el-form-item>
              <el-form-item label="示例图" prop="img_amount">
                <!-- <el-input
                  v-model="ruleForm.img_amount"
                  type="number"
                ></el-input> -->
                <div class="but-class" style="padding: 10px 0">
                  <el-upload
                    class="avatar-uploader2"
                    action="/resource/v1/uploadFile"
                    :headers="headersObj"
                    :show-file-list="false"
                    :on-success="actBodySuccess"
                    :before-upload="beforeAvatarUpload"
                    multiple
                    :limit="100"
                  >
                    <el-button type="primary" icon="el-icon-share"
                      >添加图片</el-button
                    >
                  </el-upload>
                </div>
                <PhotoUpdate
                  :title="title"
                  ref="PhotoUpdate"
                  :imageList="imageList"
                  @change="sortChange"
                ></PhotoUpdate>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >立即创建</el-button
                >
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/api/api";
import PhotoUpdate from "./PhotoUpdate.vue";
export default {
  components: {
    PhotoUpdate,
  },
  data() {
    return {
      title: "创建图片组别",
      tableData: [],
      total: 0,
      limit: 10,
      page: 1,
      formState: false,
      ruleForm: {
        name: "",
        intro: "",
      },
      rules: {
        name: [{ required: true, message: "请输入组别名称", trigger: "blur" }],
        intro: [{ required: true, message: "请输入组别简介", trigger: "blur" }],
      },
      id: 0,

      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      loadingAlert: false,
      imageList: [
        // {
        //   img: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500",
        //   img_id: 0,
        //   intro: "蹦",
        //   openedit: false,
        // },
        // {
        //   img: "https://img1.baidu.com/it/u=1407750889,3441968730&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=799",
        //   img_id: 0,
        //   intro: "",
        //   openedit: false,
        // },
        // {
        //   img: "https://img0.baidu.com/it/u=1721391133,702358773&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=625",
        //   img_id: 0,
        //   intro: "",
        //   openedit: false,
        // },
        // {
        //   img: "https://img1.baidu.com/it/u=3316754777,2519856621&fm=253&fmt=auto&app=138&f=JPEG?w=653&h=500",
        //   img_id: 0,
        //   intro: "",
        //   openedit: false,
        // },
        // {
        //   img: "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
        //   img_id: 0,
        //   intro: "",
        //   openedit: false,
        // },
        // {
        //   img: "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
        //   img_id: 0,
        //   intro: "",
        //   openedit: false,
        // },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = [
        { page: this.page, limit: this.limit },
        "/edu/v1/listEduCartoonGroup",
        "GET",
      ];
      fetchData(...params).then((res) => {
        this.total = res.data.count;
        this.tableData = res.data.list;
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleAdd() {
      this.id = 0;
      this.title = "创建图片组别";
      this.formState = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.ruleForm = {
        name: item.name,
        intro: item.intro,
      };
      let editImg = [];
      item.edu_cartoon_images.forEach((v) => {
        editImg = [
          ...editImg,
          {
            img: v.real_image_url,
            img_url: v.img_url,
            openedit: false,
            intro: v.intro,
            title: v.title,
          },
        ];
      });
      this.imageList = editImg;
      this.title = "编辑图片组别";
      this.formState = true;
    },
    submitForm(formName) {
      if (this.$refs.PhotoUpdate.images.length <= 0) {
        return this.$message.error("示例图必须上传");
      }
      let edu_cartoon_image_data = [];
      console.log(this.imageList);
      this.$refs.PhotoUpdate.images.forEach((item, index) => {
        edu_cartoon_image_data = [
          ...edu_cartoon_image_data,
          {
            img_url: item.img_url,
            intro: item.intro,
            sort: index + 1,
            title: item.title,
          },
        ];
      });
      console.log(JSON.stringify(edu_cartoon_image_data));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title === "创建图片组别") {
            const params = [
              {
                ...this.ruleForm,
                edu_cartoon_image_data: edu_cartoon_image_data,
              },
              "/edu/v1/createEduCartoonGroup",
              "POST",
            ];
            fetchData(...params).then(() => {
              this.$message.success("新增成功");
              this.getList();
              this.formState = false;
            });
          } else {
            const params = [
              {
                ...this.ruleForm,
                group_id: this.id,
                edu_cartoon_image_data: edu_cartoon_image_data,
              },
              "/edu/v1/editEduCartoonGroup",
              "POST",
            ];
            fetchData(...params).then(() => {
              this.$message.success("编辑成功");
              this.getList();
              this.formState = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**删除 */
    handleRemove(id) {
      this.$alert("删除组别请慎重", "删除组别", {
        confirmButtonText: "确定",
        callback: () => {
          const params = [
            { group_id: id },
            "/edu/v1/deleteEduCartoonGroup",
            "POST",
          ];
          fetchData(...params).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        },
      });
    },
    /**重置 */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**查看动作图片 */
    handleCheck(item) {
      console.log(item);
      this.$emit("handleCheck", item);
    },
    actBodySuccess(data) {
      console.log(data);
      // this.actBodyObj.body_image_url = data.data.relativeUrl;
      //   this.actBodyObj.body_image_id = data.data.id;
      this.addImgItem(data.data.id, data.data.relativeUrl, data.data.url);
      //   console.log(this.actBodyList.length);
      this.loadingAlert.close();
    },
    /***图片上传 */
    beforeAvatarUpload(file) {
      console.log(123);
      if (file.type.indexOf("video") > -1) {
        alert("不可以上传视频封面");
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      this.loadingAlert = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // return isLt2M;
    },
    addImgItem(img_id, body_image_url, url) {
      console.log(this.imageList);
      console.log(this.$refs.PhotoUpdate.images);
      this.imageList = [
        ...this.$refs.PhotoUpdate.images,
        {
          img: body_image_url,
          img_url: url,
          img_id: img_id,
          openedit: false,
          intro: "",
          title: "",
        },
      ];
      console.log(this.imageList);
    },
    sortChange(newList) {
      this.imageList = newList;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.pagination {
  text-align: start;
}

::v-deep .el-upload--text {
  width: auto;
  height: auto;
  border: none;
  overflow: inherit;
}
</style>
