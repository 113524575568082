"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    expose: [],
    props: ['modelValue'],
    emits: ['update:modelValue', 'change'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var value = (0, vue_3.ref)(props.modelValue);
        (0, vue_3.watchEffect)(function () { value.value = props.modelValue; });
        var confirmable = (0, vue_3.computed)(function () { return props.modelValue != value.value; });
        function confirm() {
            emit("update:modelValue", value.value);
            emit("change", value.value);
        }
        return function (_ctx, _cache) {
            var _component_el_button = (0, vue_2.resolveComponent)("el-button");
            var _component_el_input = (0, vue_2.resolveComponent)("el-input");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)("div", null, [
                (0, vue_2.createVNode)(_component_el_input, {
                    style: { "width": "140px" },
                    modelValue: value.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return (value.value = $event); })
                }, {
                    append: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_el_button, {
                            disabled: !(0, vue_2.unref)(confirmable),
                            onClick: confirm,
                            icon: "el-icon-check"
                        }, null, 8, ["disabled"])
                    ]; }),
                    _: 1
                }, 8, ["modelValue"])
            ]));
        };
    }
});
