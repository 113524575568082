<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 套餐列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="openVisible('add')">添加套餐</el-button>
                <!-- <el-button
                    type="primary"
                    icon="el-icon-search"
                    class="right"
                    @click="handleSearch"
                    >搜索</el-button
                >
                <el-input
                    v-model="query.name"
                    placeholder="套餐名"
                    class="handle-input mr10 right"
                ></el-input> -->
            </div>
            <el-table :data="goodsList" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="name" label="套餐名"></el-table-column>
                <!-- <el-table-column label="类别">
                    <template #default="scope">
                        {{
                            scope.row.goods_category === "by_member_count"
                                ? "帐号计数"
                                :  (scope.row.goods_category === "by_lesson_fee" ? "课时记费" : "时长计数")
                        }}
                    </template>
                </el-table-column> -->
                <el-table-column label="标签">
                    <template #default="scope">
                        <admin-label category="goods" :categoryId="scope.row.id" style="font-size:12px;"></admin-label>
                    </template>
                </el-table-column>
                <el-table-column prop="course_name" label="绑定课程"></el-table-column>
                <el-table-column prop="disable_student_account" label="是否为包年套餐">
                    <template #default="scope">
                        <span v-if="scope.row.disable_student_account" style="color: #F56C6C;">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="hours"
                    label="预设时长(小时)"
                ></el-table-column> -->
                <el-table-column prop="days" label="时长（天）"></el-table-column>
                <!-- <el-table-column
                    prop="member_count"
                    label="账号个数"
                ></el-table-column> -->
                <el-table-column prop="price" label="价格(元)"></el-table-column>
                <!-- <el-table-column
                    prop="student_account_limit"
                    label="学生账户数量"
                ></el-table-column>
                <el-table-column
                    prop="teacher_account_limit"
                    label="教师账户数量"
                ></el-table-column> -->
                <!-- <el-table-column
                    prop="week_limit"
                    label="周限制"
                ></el-table-column> -->
                <el-table-column prop="create_time" label="添加时间"></el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click="openVisible('edit', scope.row)">编辑
                        </el-button>
                        <el-button type="text" icon="el-icon-edit" @click="goodsBindingCourse(scope.row)">套餐绑定课程
                        </el-button>
                        <el-button type="text" icon="el-icon-delete" class="red"
                            @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>

        <!-- 弹出框 -->
        <el-dialog :title="(visibleType == 'add' ? '添加' : '修改') + '套餐'" v-model="visible" width="30%">
            <el-form ref="form" :model="goodsData" label-width="120px">
                <el-form-item label="套餐名">
                    <el-input v-model="goodsData.name"></el-input>
                </el-form-item>

                <el-form-item label="套餐类型" hidden>
                    <el-radio-group v-model="goodsData.goods_category">
                        <el-radio-button disabled label="by_member_count">记帐号个数</el-radio-button>
                        <el-radio-button disabled label="by_hours">记小时数</el-radio-button>
                        <el-radio-button disabled label="by_lesson_fee">记课时费用</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="有效天数(天)">
                    <el-input type="number" v-model="goodsData.days"></el-input>
                </el-form-item>
                <el-form-item v-if="goodsData.goods_category == 'by_hours'" label="可用时长(小时)" hidden>
                    <el-input v-model="goodsData.hours" type="number"></el-input>
                </el-form-item>
                <el-form-item label="账号个数" hidden>
                    <el-input v-model="goodsData.member_count" type="number"></el-input>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input v-model="goodsData.price" type="number"></el-input>
                </el-form-item>
                <!-- <el-form-item label="学生账户数量">
                    <el-input v-model="goodsData.student_account_limit" type="number"></el-input>
                </el-form-item>
                <el-form-item label="教师账户数量">
                    <el-input v-model="goodsData.teacher_account_limit" type="number"></el-input>
                </el-form-item> -->
                <el-form-item label="课时时长">
                    <el-input v-model="goodsData.lesson_duration" type="number"></el-input>
                </el-form-item>
                <el-form-item label="是否为包年套餐">
                    <el-switch v-model="goodsData.disable_student_account"></el-switch>
                </el-form-item>
                <el-form-item label="周限制" hidden>
                    <el-input v-model="goodsData.week_limit" type="number"></el-input>
                </el-form-item>
                <el-form-item label="可见类型" hidden>
                    <el-radio v-model="goodsData.target_user" label="all">所有用户</el-radio>
                    <el-radio v-model="goodsData.target_user" label="teacher">仅老师可见</el-radio>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveFun">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <!-- <el-dialog title="添加套餐" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="120px">
                <el-form-item label="套餐名">
                    <el-input v-model="addData.name"></el-input>
                </el-form-item>
                <el-form-item label="套餐类型">
                    <el-radio-group v-model="addData.goods_category">
                        <el-radio-button label="by_member_count">记帐号个数</el-radio-button>
                        <el-radio-button label="by_hours">记小时数</el-radio-button>
                        <el-radio-button label="by_lesson_fee">记课时费用</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="有效天数(天)">
                    <el-input v-model="addData.days" type="number"></el-input>
                </el-form-item>
                <el-form-item v-if="addData.goods_category == 'by_hours'" label="可用时长(小时)">
                    <el-input v-model="addData.hours" type="number"></el-input>
                </el-form-item>
                <el-form-item label="老师账号个数" hidden>
                    <el-input
                        v-model="addData.member_count"
                        type="number"
                        :value="20000"
                    ></el-input>
                </el-form-item>
                <el-form-item label="价格" v-if="addData.goods_category !== 'by_lesson_fee'">
                    <el-input v-model="addData.price" type="number"></el-input>
                </el-form-item>
                <el-form-item label="课时时长" >
                    <el-input v-model="form.lesson_duration" type="number"></el-input>
                </el-form-item>
                <el-form-item label="可见类型">
                    <el-radio v-model="addData.target_user" label="all">所有用户</el-radio>
                    <el-radio v-model="addData.target_user" label="teacher">仅老师可见</el-radio>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog> -->
        <!-- 套餐绑定课程 -->
        <el-dialog title="绑定套餐到课程" v-model="bindVisible" width="30%">
            <el-form ref="form" :model="bindData" label-width="70px">
                <el-form-item label="选择课程">
                    <el-select v-model="bindData.course_id" filterable placeholder="请选择">
                        <el-option v-for="item in courseData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveBind">确 定</el-button>
                </span>
            </template>
            <!-- <GroupPairStudent :bindVisible="bindVisible" :groupId="form.group_id" @cloneStatus="aa"></GroupPairStudent> -->
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import AdminLabel from "../components/resource/adminTag/AdminLabel.vue";
export default {
    name: "goods",
    data() {
        return {
            query: {
                address: "",
                name: "",
                page: 1,
                limit: 10,
            },
            addData: {
                price: 0,
                hours: 0
            },
            bindData: {},
            goodsList: [],
            courseData: [],
            multipleSelection: [],
            delList: [],
            visible: false,
            editVisible: false,
            addVisible: false,
            bindVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            imageUrl: "",

            visibleType: "",
            goodsData: {}
        };
    },
    components: {
        AdminLabel
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(this.query, "/edu/v1/listGoods", "GET").then((res) => {
                this.goodsList = res.data.list;
                this.pageTotal = res.data.count || 0;
            });
            fetchData(
                { page: 1, limit: 999999 },
                "/admin/v1/listCourse",
                "GET"
            ).then((res) => {
                this.courseData = res.data.list;
            });
        },
        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            this.query.name = "";
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { goods_id: id },
                        "/edu/v1/deleteGoods",
                        "GET"
                    ).then(() => {
                        this.$message.success("删除成功");
                        this.goodsData.splice(index, 1);
                    });
                })
                .catch(() => { });
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },

        initGoodsData(data) {
            this.goodsData = {
                id: data ? data.id : '',
                days: data ? data.days : '',
                member_count: data ? data.member_count : 20000,
                price: data ? data.price : '',
                name: data ? data.name : '',
                hours: data ? data.hours : 10,
                goods_category: data ? data.goods_category : 'by_memeber_count',
                target_user: data ? data.target_user : 'all',
                student_account_limit: data ? data.student_account_limit : 0,
                teacher_account_limit: data ? data.teacher_account_limit : 0,
                lesson_duration: data ? data.lesson_duration : '',
                week_limit: data ? data.week_limit : '',
                disable_student_account: data ? data.disable_student_account : false
            };
        },
        // 打开添加或编辑弹框
        openVisible(type, data = null) {
            this.visible = true;
            this.visibleType = type;
            this.initGoodsData(data);
        },
        // 保存操作
        saveFun() {
            var url = "";
            var text = "";
            if (this.visibleType == 'add') {
                url = "/edu/v1/createGoods";
                text = '添加成功';
            }
            if (this.visibleType == 'edit') {
                url = "/edu/v1/editGoods";
                text = '修改成功';
            }
            // console.log(this.goodsData);
            this.goodsData.week_limit = 0;
            fetchData(this.goodsData, url, "POST").then(() => {
                this.getData();
                this.$message.success(text);
                this.visible = false;
            });
        },
        // // 添加操作
        // goodsAdd() {
        //     this.addVisible = true;
        // },
        // // 编辑操作
        // handleEdit(index, row) {
        //     this.idx = index;
        //     this.form = row;
        //     this.editVisible = true;
        // },
        // // 保存编辑
        // saveEdit() {
        //     fetchData(this.form, "/edu/v1/editGoods", "POST").then(() => {
        //         this.$message.success(`修改第 ${this.idx + 1} 行成功`);
        //         this.editVisible = false;
        //     });
        // },
        // // 保存添加
        // saveAdd() {
        //     fetchData(this.addData, "/edu/v1/createGoods", "POST").then(
        //         () => {
        //             this.$message.success(`添加成功`);
        //             this.addVisible = false;
        //             this.getData();
        //         }
        //     );
        // },
        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            // this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
        goodsBindingCourse(row) {
            this.bindVisible = true;
            this.bindData.goods_id = row.id;
            this.bindData.course_id = row.course_id;
        },
        // 提交绑定
        saveBind() {
            fetchData(
                this.bindData,
                "/admin/v1/goodsBindingToCourse",
                "POST"
            ).then(() => {
                this.$message.success(`绑定成功`);
                this.bindVisible = false;
            });
            // this.$set(this.tableData, this.idx, this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>
